<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { TEST_RESEND_EMAIL } from '@/constants/dataTestAttrs';

const props = defineProps<{
	text: string;
	timer: number;
}>();

const timer = ref(0);

const emits = defineEmits<{
	(e: 'click'): void;
}>();

const cooldownTimer = computed(() => {
	// in html from (0:15) to (0:01)
	return `(0:${timer.value < 10 ? 0 : ''}${timer.value.toString()})`;
});

watch(
	() => timer.value,
	(newValue) => {
		if (newValue > 0) {
			setTimeout(() => {
				timer.value = newValue - 1;
			}, 1000);
		}
	},
	{ immediate: true }
);

const handleClick = () => {
	if (timer.value > 0) return;
	emits('click');
};

const restartTimer = () => {
	timer.value = props.timer;
};

defineExpose({
	restartTimer,
});

const buttonLabel = computed(
	() => `${props.text} ${timer.value > 0 ? cooldownTimer.value : ''}`
);
</script>

<template>
	<PrimeButton
		type="link"
		class="button-resend"
		:label="buttonLabel"
		:class="{ available: timer === 0 }"
		:disabled="timer > 0"
		:color="timer > 0 ? 'disabled' : 'primary'"
		:data-test="TEST_RESEND_EMAIL"
		@click="handleClick"
	/>
</template>

<style lang="scss">
.button-resend {
	text-decoration: underline;
	cursor: wait;

	&:hover {
		text-decoration: underline;

		&.available {
			cursor: pointer;
		}
	}
}
</style>
