<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useWindowScroll } from '@vueuse/core';
import { useNavDesktopItems } from '@/components/common/header/composables';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Capacitor } from '@capacitor/core';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRoute } from 'vue-router';

const route = useRoute();
const { items } = useNavDesktopItems();
const { y: axisY } = useWindowScroll();
const authStore = useAuthStore();
const { isMobileNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();

watch(
	axisY,
	(newValue, oldValue) => {
		// show mobile nav when scrolling on top direction
		if (oldValue && newValue < oldValue) {
			!isMobileNavVisible.value &&
				authStore.setMobileNavigationVisibility(true);
		} else {
			// 64 -- height of the mobile nav
			if (newValue > 80) {
				// hide mobile nav when offset from top position is more than 64px
				isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(false);
			} else {
				// show mobile nav when offset from top position is less than 64px
				!isMobileNavVisible.value &&
					authStore.setMobileNavigationVisibility(true);
			}
		}
	},
	{ immediate: true }
);

const isIosPlatform = computed(
	() => isMobileApp && Capacitor.getPlatform() === 'ios'
);

const paddingBottom = computed(() =>
	isIosPlatform.value ? `calc(75px + env(safe-area-inset-bottom))` : `80px`
);

const paddingBottomHidden = computed(() =>
	isIosPlatform.value ? `75px` : `60px`
);

const bottom = computed(() =>
	isIosPlatform.value ? `calc(-65px - env(safe-area-inset-bottom))` : `-80px`
);

const height = computed(() =>
	isIosPlatform.value ? `calc(160px + env(safe-area-inset-bottom))` : `160px`
);
</script>

<template>
	<nav class="app-mobile-nav" :class="{ hidden: !isMobileNavVisible }">
		<div class="app-mobile-nav__wrapper">
			<RouterLink
				v-for="link in items.filter((item) => item.active)"
				:key="link.to"
				:to="link.to"
				:data-test="`mobile-navigation-${link.test}`"
				class="app-mobile-nav__item"
			>
				<div class="app-mobile-nav__icon">
					<component
						:is="route.path === link.to ? link.iconActive : link.icon"
						color="inherit"
						size="1.5rem"
					/>
				</div>
				<PrimeText size="sm" weight="500" color="grey-800">
					{{ link.label }}
				</PrimeText>
			</RouterLink>
		</div>
	</nav>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-mobile-nav {
	padding-bottom: v-bind(paddingBottom);
	bottom: v-bind(bottom);
	height: v-bind(height);
	position: fixed;
	z-index: 20;
	left: 0;
	right: 0;
	contain: paint;
	background-color: var(--p-grey-50);
	transition: height 0.2s ease-in-out;

	@include breakpoint(tablet) {
		display: none;
	}

	&.hidden {
		height: 0;
		transition: height 0.2s ease-in-out;
		padding-bottom: v-bind(paddingBottomHidden);
	}

	&__wrapper {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: space-evenly;
		align-items: center;
	}

	&__item {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
		padding: 0 0.625rem;
		height: 100%;
		color: var(--p-grey-800);

		&.router-link-active {
			.app-mobile-nav__icon {
				color: var(--p-text-default);
				background-color: rgba(var(--p-secondary-base-rgb), 0.4);
			}
		}
	}

	&__icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0.25rem 1.25rem;
		border-radius: 2rem;
		color: var(--p-grey-800);
	}
}
</style>
