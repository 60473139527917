<script lang="ts" setup>
import { ref, watch, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { changeTimeZone } from '@/api';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import Dropdown from 'primevue/dropdown';
import { timezones } from '@/constants/timezones';
import { CHANGE_TIMEZONE, SAVE_CHANGES } from '@/constants/locales';
import {
	TEST_MODAL_TIMEZONE_SELECT,
	TEST_MODAL_TIMEZONE_SAVE,
} from '@/constants/dataTestAttrs';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const userStore = useUserStore();
const loading = ref(false);
const { timezone } = storeToRefs(userStore);
const userTimezone = ref('');

const setUserTimezone = () => {
	const tz = timezones.find(
		(tz: Record<string, string>) => tz.code === timezone.value
	);
	userTimezone.value = tz?.code || '';
};

watch(timezone, (value: string) => {
	if (value) {
		setUserTimezone();
	}
});

onBeforeMount(() => {
	setUserTimezone();
});

const handleSubmit = async () => {
	try {
		loading.value = true;
		await changeTimeZone(userTimezone.value);
		userStore.setTimezone(userTimezone.value);
		handleCloseModal();
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent>
		<template #header>{{ $t(CHANGE_TIMEZONE) }}</template>
		<template #inputs>
			<Dropdown
				v-if="timezone && timezones.length"
				v-model="userTimezone"
				:options="timezones"
				option-label="label"
				option-value="code"
				filter
				:input-props="{
					'data-test': TEST_MODAL_TIMEZONE_SELECT,
				}"
			/>
		</template>
		<template #footer>
			<PrimeButton
				type="text"
				variant="grey"
				rounded="md"
				:label="$t(SAVE_CHANGES)"
				:data-test="TEST_MODAL_TIMEZONE_SAVE"
				@click="handleSubmit"
			/>
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.modal-common-content {
	:deep(.content-inputs) {
		margin-top: 0;
	}

	:deep(.content-inputs .p-dropdown) {
		width: 100%;

		.p-dropdown-label {
			text-align: start;
		}
	}
}
</style>
