<script lang="ts" setup>
import {
	EMAIL_SUBS_TITLE,
	EMAIL_SUBS_SHOW_ALL,
	SAVE,
} from '@/constants/locales';
import { useEmailSubscriptionsView } from '@/views/email-subscriptions/composables';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PageCard from '@/components/common/PageCard.vue';
import SubscriptionItem from '@/views/email-subscriptions/components/SubscriptionItem.vue';
import { TEST_EMAIL_PREFERENCES_SAVE } from '@/constants/dataTestAttrs';

const {
	subsToShow,
	isAllSubsShow,
	isAuthorized,
	emailSubsTranslations,
	handleSave,
} = useEmailSubscriptionsView();
</script>

<template>
	<main class="subscriptions">
		<PageCard>
			<PrimeText class="subscriptions-title" tag="h2" size="sm" weight="500">
				{{ $t(EMAIL_SUBS_TITLE) }}
			</PrimeText>
			<div v-if="subsToShow">
				<SubscriptionItem
					v-for="(subscription, index) in subsToShow"
					:key="index"
					:type="subscription.type"
					:subscribed="subscription.subscribed"
					:title="$t(emailSubsTranslations[subscription.type]?.title)"
					:description="
						$t(emailSubsTranslations[subscription.type]?.description)
					"
					@change="subscription.subscribed = $event"
				/>
			</div>
			<template v-if="!isAuthorized">
				<PrimeButton
					v-if="!isAllSubsShow"
					class="show-all-button"
					type="link"
					:label="$t(EMAIL_SUBS_SHOW_ALL)"
					@click="isAllSubsShow = true"
				/>
			</template>
			<div class="subscriptions-footer">
				<PrimeButton
					class="save-button"
					variant="secondary"
					:label="$t(SAVE)"
					full-width
					:disabled="!subsToShow"
					:data-test="TEST_EMAIL_PREFERENCES_SAVE"
					@click="handleSave"
				/>
			</div>
		</PageCard>
	</main>
</template>

<style lang="scss" scoped>
.subscriptions {
	width: 100%;
}

.subscriptions-title {
	margin-bottom: 1rem;
}

.show-all-button {
	text-decoration: underline;
}

.subscriptions-footer {
	margin-top: 2.25rem;
	text-align: center;

	.save-button {
		max-width: 21.875rem;
	}
}
</style>
