<script setup lang="ts">
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import {
	NOT_YOUR,
	SIGN_IN,
	SOCIAL_ACCOUNT_MESSAGE,
	USE_ANOTHER_ACCOUNT,
} from '@/constants/locales';
import SocialButton from '@/components/auth/SocialButton.vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useSocialsView } from '@/views/socials/composables';
import { PiEnvelopeSimple } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleLoadingSocials,
	handleAnotherAccount,
	socialAuth,
	usersSocials,
	isLoading,
	formattedEmail,
} = useSocialsView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_IN)">
		<div class="social-account-wrapper">
			<PrimeText
				tag="div"
				weight="500"
				class="social-account-message"
				v-html="$t(SOCIAL_ACCOUNT_MESSAGE)"
			/>
			<div class="social-account-email">
				<PiEnvelopeSimple size="2rem" />
				<PrimeText weight="600" size="lg">{{ formattedEmail }}</PrimeText>
			</div>
		</div>

		<SocialButton
			:provider="usersSocials[0]"
			@loading="handleLoadingSocials($event)"
			@auth="socialAuth($event)"
		/>

		<div class="social-account-change">
			<PrimeText size="xs">{{ $t(NOT_YOUR) }}</PrimeText>
			<button @click="handleAnotherAccount">
				<PrimeText size="xs">{{ $t(USE_ANOTHER_ACCOUNT) }}</PrimeText>
			</button>
		</div>
		<PrimeLoader v-if="isLoading" full-page />
	</AuthPageContent>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.social-account-wrapper {
	padding: 1.25rem 0.625rem;
	border-radius: var(--p-radius-sm);
	border: 1px solid var(--p-grey-300);
	background: var(--p-grey-200);
	margin-bottom: 1.375rem;
}

.social-account-message {
	margin-bottom: 0.75rem;
}

.social-account-email {
	padding: 0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
}

.social-account-change {
	margin-top: 0.75rem;

	& button {
		cursor: pointer;
		border: none;
		background: transparent;
		text-decoration: underline;

		@include breakpoint(mobile) {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				color: var(--p-black);
			}
		}
	}
}
</style>
