import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useSocialAuth } from '@/utils/composables/useSocialAuth';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { getHiddenEmail } from '@/utils/helpers';

export const useSocialsView = (): {
	handleLoadingSocials: (loading: boolean) => void;
	handleAnotherAccount: () => Promise<void>;
	socialAuth: (provider: string) => Promise<void>;
	usersSocials: ComputedRef<string[]>;
	isLoading: Ref<boolean>;
	formattedEmail: ComputedRef<string>;
} => {
	const router = useRouter();
	const authStore = useAuthStore();
	const { usersSocials, authUserEmail } = storeToRefs(authStore);
	const { socialAuth } = useSocialAuth();

	const isLoading = ref(false);

	const handleLoadingSocials = (loading: boolean) => {
		isLoading.value = loading;
	};

	const formattedEmail = computed(() => getHiddenEmail(authUserEmail.value));

	const handleAnotherAccount = async () => {
		await router.push({
			name: 'app-login',
		});
	};

	return {
		handleLoadingSocials,
		handleAnotherAccount,
		socialAuth,
		usersSocials,
		isLoading,
		formattedEmail,
	};
};
