import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useComponentName } from '@/utils/composables/useComponentName';
import type { ComputedRef, Ref } from 'vue';
import { onBeforeUnmount, ref, watch } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { sendErrorInfo } from '@/utils/errorCatching';
import type { SocialCallbackResponse } from '@/types';

type RegisterForm = { email: string };

export const useSignupSocialView = (): {
	handleSubmitForm: () => Promise<void>;
	userSocialAuthData: ComputedRef<SocialCallbackResponse | null>;
	form: Ref<RegisterForm>;
	isLoading: Ref<boolean>;
	getValidationFieldStatus: (
		field: keyof RegisterForm
	) => 'error' | null | undefined;
	getValidationFieldText: (
		field: keyof RegisterForm
	) => string | null | undefined;
} => {
	const authStore = useAuthStore();
	const { authErrors, userSocialAuthData } = storeToRefs(authStore);
	const componentName = useComponentName();

	const isLoading = ref(false);
	const form = ref<RegisterForm>({
		email: userSocialAuthData.value?.email || '',
	} as RegisterForm);
	const serverValidationResults = ref();
	const acceptPolicyEl = ref<{
		policyValidationReset: () => void;
		policyValidate: () => Promise<boolean>;
		policyValidationError: ComputedRef<boolean>;
	} | null>(null);

	const { email } = useValidationRules();

	const validation = useVuelidate({ email }, form, {
		$autoDirty: false,
		$externalResults: serverValidationResults,
	});

	const {
		reset,
		clear,
		validate,
		isValidationError,
		getValidationFieldStatus,
		getValidationFieldText,
	} = useValidationUtils<RegisterForm>(validation);

	const handleSubmitForm = async (): Promise<void> => {
		clear();
		await validate();
		await acceptPolicyEl.value?.policyValidate();

		if (
			isValidationError.value ||
			acceptPolicyEl.value?.policyValidationError
		) {
			return;
		}

		try {
			isLoading.value = true;
			await authStore.registerSocial(form.value.email);
		} catch (error) {
			await sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	onBeforeUnmount(() => {
		form.value.email = '';
		reset();
		acceptPolicyEl.value?.policyValidationReset();
	});

	watch(authErrors, (newErrors) => {
		serverValidationResults.value = { email: newErrors?.message };
	});

	return {
		handleSubmitForm,
		userSocialAuthData,
		form,
		isLoading,
		getValidationFieldStatus,
		getValidationFieldText,
	};
};
