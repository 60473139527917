import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import type { Ref, ComputedRef } from "vue";
import { supportedCountries } from "@/constants/localizations";
import { getCountryName } from "@/utils/helpers";
import { handleUserLocale } from "@/i18n";

export const useLocalizationView = (): {
	selectedLocalization: Ref<string>;
	saveSelectedLanguage: () => void;
	handleSelectLocalization: (localization: string) => void;
	country: ComputedRef<string>;
	localization: ComputedRef<string>;
	filteredCountries: ComputedRef<string[]>;
	searchQuery: Ref<string>;
} => {
	const router = useRouter();
	const authStore = useAuthStore();
	const { country, localization } = storeToRefs(authStore);

	const selectedLocalization = ref('');
	const searchQuery = ref('');

	const handleSelectLocalization = (localization: string) => {
		selectedLocalization.value = localization;
	};

	const filteredCountries = computed(() => {
		return supportedCountries.filter((country: any) =>
			getCountryName(country)
				.toLowerCase()
				.includes(searchQuery.value.toLowerCase())
		);
	});

	const saveSelectedLanguage = () => {
		handleUserLocale(selectedLocalization.value);
		router.push({ name: 'app-login' });
	};

	return {
		selectedLocalization,
		saveSelectedLanguage,
		handleSelectLocalization,
		country,
		localization,
		filteredCountries,
		searchQuery
	}
}
