<script setup lang="ts">
import { onMounted } from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import ClaimedRewardItem from '@/views/history/views/rewards/components/ClaimedRewardItem.vue';
import PrimeSkeleton from '@/components/common/PrimeSkeleton.vue';
import ClaimedRewardsFallback from '@/views/history/views/rewards/components/ClaimedRewardsFallback.vue';
import TotalClaims from '@/views/history/views/rewards/components/TotalClaims.vue';

const rewardsStore = useRewardsStore();

onMounted(async () => {
	await rewardsStore.fetchRewardsHistory();
});
</script>

<template>
	<TotalClaims />
	<div class="rewards">
		<template v-if="rewardsStore.loading">
			<PrimeSkeleton v-for="i in 3" :key="i" class="rewards-skeleton" />
		</template>
		<template v-else-if="rewardsStore.claimedRewards?.length">
			<ClaimedRewardItem
				v-for="item in rewardsStore.claimedRewards"
				:key="item.date"
				:item="item"
			/>
		</template>
		<ClaimedRewardsFallback v-else />
	</div>
</template>

<style lang="scss" scoped>
.rewards {
	padding: 1.5rem 0;
}
.rewards-skeleton {
	height: 7.4375rem;
	width: 100%;
}
</style>
