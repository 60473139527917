import { ClaimStatuses } from '@/enums';
import {
	CONFIRM_VIA_EMAIL,
	DONE,
	ERROR,
	PROCESSING,
	REFUNDED,
} from '@/constants/locales';
import {
	PiCheckCircle,
	PiClock,
	PiQuestionCircle,
	PiWarningCircle,
} from '@primeinsightsgroupllc/prime-icons';
import type { ClaimStatusesContent } from '@/types';

export const CLAIM_STATUS_CONTENT: ClaimStatusesContent = {
	[ClaimStatuses.SUCCESS]: {
		text: DONE,
		icon: PiCheckCircle,
		variant: 'primary',
	},
	[ClaimStatuses.REFUND]: {
		text: REFUNDED,
		icon: PiCheckCircle,
		variant: 'secondary',
	},
	[ClaimStatuses.FAILED]: {
		text: ERROR,
		icon: PiWarningCircle,
		variant: 'error',
	},
	[ClaimStatuses.PROCESSING]: {
		text: PROCESSING,
		icon: PiQuestionCircle,
		variant: 'secondary',
	},
	[ClaimStatuses.USER_CONFIRMATION]: {
		text: CONFIRM_VIA_EMAIL,
		icon: PiClock,
		variant: 'secondary',
	},
};
