<script setup lang="ts">
import { useConfirmClaimView } from '@/views/confirm-claim/composables';
import { PrimeDivider } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ConfirmClaimTop from '@/views/confirm-claim/components/ConfirmClaimTop.vue';

const { component } = useConfirmClaimView();
</script>

<template>
	<section class="claim-container">
		<div class="wrapper">
			<ConfirmClaimTop />

			<PrimeDivider class="divider" />

			<component :is="component" />
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.claim-container {
	width: 100%;
	max-width: 34.375rem;
	display: flex;
	flex-direction: column;

	.wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		background: var(--p-white);
		border-radius: 1.5rem;
		padding: 1.5rem 0.8125rem 0.25rem 0.8125rem;

		box-shadow:
			0 0.5px 1.5px 0 rgba(0, 0, 0, 0.19),
			0 0 1px 0 rgba(0, 0, 0, 0.04);

		@include breakpoint(mobile) {
			padding: 1.125rem 1.125rem 0 1.125rem;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: var(--p-grey-50);
		margin-bottom: 1.25rem;
	}
}
</style>
