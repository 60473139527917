<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useRewardsStore } from '@/stores/rewards';
import { ref, watch } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { CONFIRM_CLAIM_DATA_MODAL } from '@/constants/modals';
import {
	CHANGE_EMAIL,
	CLAIM_EMAIL,
	CONFIRM_PAYPAL_EMAIL,
	CONFIRM_PAYPAL_MESSAGE,
	EMAIL,
} from '@/constants/locales';
import ConfirmClaimWrapper from '@/views/confirm-claim/components/ConfirmClaimWrapper.vue';
import { PrimeInput, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

export type CheckEmail = { email: string };

const userStore = useUserStore();
const { lastClaimEmail } = storeToRefs(userStore);

const rewardsStore = useRewardsStore();
const { selectedReward } = storeToRefs(rewardsStore);

const isLoading = ref(false);

const form = ref<CheckEmail>({ email: lastClaimEmail.value } as CheckEmail);
const { email } = useValidationRules();
const validation = useVuelidate({ email }, form, { $autoDirty: false });

const {
	reset,
	validate,
	isValidationError,
	getValidationFieldStatus,
	getValidationFieldText,
} = useValidationUtils<CheckEmail>(validation);

const handleConfirmClaim = async () => {
	await validate();
	if (isValidationError.value) return;

	try {
		isLoading.value = true;
		const { askConfirm } = await rewardsStore.claim(form.value);

		if (askConfirm) {
			await useModalStorage().pushModal({
				name: CONFIRM_CLAIM_DATA_MODAL,
				options: {
					userData: form.value.email,
					header: CONFIRM_PAYPAL_EMAIL,
					message: CONFIRM_PAYPAL_MESSAGE,
					cancelLabel: CHANGE_EMAIL,
					confirmParams: { email: form.value.email, force_new_email: '1' },
				},
			});
		}
		reset();
	} finally {
		isLoading.value = false;
	}
};

watch(() => form.value.email, reset);
</script>

<template>
	<ConfirmClaimWrapper
		:disabled="!form.email.length || isValidationError"
		:loading="isLoading"
		@confirm="handleConfirmClaim"
	>
		<template #claim-data>
			<PrimeText weight="500" size="sm">
				{{ $t(CLAIM_EMAIL, { value: selectedReward?.name }) }}
			</PrimeText>
			<PrimeInput
				v-model="form.email"
				:placeholder="$t(EMAIL)"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
				type="email"
			/>
		</template>
	</ConfirmClaimWrapper>
</template>
