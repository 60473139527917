<script setup lang="ts">
import { PrimeInput } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiSearch } from '@primeinsightsgroupllc/prime-icons';

const searchQuery = defineModel<string>('search', { required: true });

defineProps<{
	placeholder: string;
}>();
</script>

<template>
	<div class="search-reward-wrapper">
		<PiSearch size="1.25rem" class="search-reward-icon" />
		<PrimeInput
			v-model="searchQuery"
			class="search-input"
			:placeholder="placeholder"
			:float-placeholder="true"
		/>
	</div>
</template>

<style lang="scss" scoped>
.search-reward-wrapper {
	position: relative;
	margin-bottom: 0.625rem;

	:deep(.p-input-wrapper) {
		box-shadow: none;
	}

	:deep(.p-input) {
		padding-left: 2.375rem;
	}

	:deep(.p-float-placeholder) {
		left: 2.375rem;
	}
}

.search-reward-icon {
	position: absolute;
	left: 0.675rem;
	top: 50%;
	margin-top: -0.75rem;
	z-index: 1;
}
</style>
