<script lang="ts" setup>
import type { ClaimedReward } from '@/types';
import { AMOUNT, DATE } from '@/constants/locales';
import RewardImage from '@/components/common/RewardImage.vue';
import ClaimRewardDetails from '@/views/history/views/rewards/components/ClaimRewardDetails.vue';
import { toDate, transformCurrency } from '@/utils/helpers';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ActionStatus from '@/components/ActionStatus.vue';
import { CLAIM_STATUS_CONTENT } from '@/views/history/views/rewards/components/statuses';

defineProps<{
	item: ClaimedReward;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';
</script>

<template>
	<div class="claim-item-container">
		<div class="claim-item-content">
			<div class="claim-item-top">
				<div class="partner-info">
					<RewardImage :src="item.reward_image_url" :alt="item.reward_name" />
					<PrimeText weight="600" size="sm" tag="p">
						{{ item.reward_name }}
					</PrimeText>
				</div>
				<ActionStatus :content="CLAIM_STATUS_CONTENT[item.status]" />
			</div>
			<div class="claim-item-bottom">
				<ClaimRewardDetails
					:title="$t(AMOUNT)"
					:data="transformCurrency(item.money_value, item.currency)"
				/>
				<ClaimRewardDetails
					:title="$t(DATE)"
					:data="toDate(item.date, localization)"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.claim-item-container {
	display: flex;
	flex-direction: column;
	padding: 0.75rem 0.8125rem;
	margin-bottom: 0.75rem;
	background-color: var(--p-white);
	border-radius: 1rem;
	box-shadow: var(--5s-shadow-card);
}

.claim-item-content {
	.claim-item-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 0.75rem;
		margin-bottom: 0.75rem;
		border-bottom: 1px solid var(--grey-200);
		font-size: 0.875rem;

		@include breakpoint(mobile) {
			font-size: 1rem;
		}
	}

	.claim-item-bottom {
		display: flex;
		justify-content: space-between;
		font-size: 0.75rem;

		@include breakpoint(mobile) {
			font-size: 0.875rem;
			grid-template-columns: 2fr 2fr 2fr;
		}

		&:last-child {
			align-items: flex-end;
		}
	}
}

.partner-info {
	display: flex;
	align-items: center;

	& p {
		margin-left: 0.5rem;
	}
}
</style>
