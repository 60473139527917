<script setup lang="ts">
import { useSlots } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const slots = useSlots();
</script>
<template>
	<div class="modal-common-content">
		<PrimeText
			v-if="slots.header"
			class="content-header"
			tag="h2"
			size="sm"
			color="dark"
		>
			<slot name="header" />
		</PrimeText>

		<span v-if="slots.icon" class="content-icon">
			<slot name="icon" />
		</span>

		<PrimeText v-if="slots.notice" class="content-notice" tag="div" size="sm">
			<slot name="notice" />
		</PrimeText>

		<PrimeText
			v-if="slots.title"
			tag="div"
			size="sm"
			weight="500"
			class="content-title"
		>
			<slot name="title" />
		</PrimeText>

		<PrimeText
			v-if="slots.description"
			tag="div"
			size="sm"
			color="grey-700"
			weight="500"
			class="content-description"
		>
			<slot name="description" />
		</PrimeText>

		<PrimeText
			v-if="slots.message"
			tag="div"
			size="sm"
			color="grey-700"
			class="content-message"
		>
			<slot name="message" />
		</PrimeText>

		<div v-if="slots.inputs" class="content-inputs">
			<slot name="inputs" />
		</div>

		<slot name="default" />

		<div
			v-if="slots.actions"
			class="content-actions"
			:class="{
				'inputs-actions': slots.inputs,
			}"
		>
			<slot name="actions" />
		</div>
		<div v-if="slots.raw" class="content-row">
			<slot name="raw" />
		</div>
		<div v-if="slots.footer" class="content-footer">
			<slot name="footer" />
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.modal-common-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 80vh;

	.content-header {
		border-bottom: 1px solid var(--p-grey-200);
		margin: 0 -1.125rem 1.5rem;
		padding: 0 4rem 1.375rem 1.125rem;
		width: calc(100% + 2.25rem);
		text-align: left;
	}

	.content-icon {
		margin-bottom: 0.625rem;
	}

	.content-icon img {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
	}

	.content-icon,
	.content-icon svg {
		width: 3.375rem;
		height: 3.375rem;

		@include breakpoint(mobile) {
			width: 5.875rem;
			height: 5.875rem;
		}
	}

	.content-title {
		margin-bottom: 0.5rem;
		width: 100%;
	}

	.content-notice {
		padding: 0.75rem;
		width: 100%;
		border-radius: var(--p-radius-sm);
		background-color: var(--p-grey-50);
		text-align: center;
	}

	.content-description {
		border-bottom: 1px solid var(--p-grey-200);
		margin-bottom: 0.75rem;
		padding-bottom: 0.75rem;
		width: 100%;
	}

	.content-message {
		width: 100%;
	}

	.content-inputs {
		width: 100%;
		margin-top: 0.75rem;
		text-align: left;

		& .p-input-wrapper:not(:first-of-type) {
			margin-top: 0.75rem;
		}
	}

	.content-actions {
		margin-top: 2.125rem;
		width: 100%;

		&.inputs-actions {
			margin-top: 1.375rem;
		}

		& .button {
			width: 100%;
			padding: 0.875rem;
		}
	}

	.content-row {
		width: 100%;
		text-align: center;
	}

	.content-footer {
		border-top: 1px solid var(--p-grey-200);
		margin: 1.5rem -1.125rem -0.25rem;
		padding: 0.75rem 1.125rem 0;
		width: calc(100% + 2.25rem);
		text-align: right;

		.p-btn {
			padding: 0.25rem;

			&--grey {
				color: var(--5s-primary-text);
			}

			.p-btn-label {
				text-transform: uppercase;
			}
		}
	}
}
</style>
