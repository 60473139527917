<script setup lang="ts">
import { PROCEED } from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { useRewardsStore } from '@/stores/rewards';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { ref } from 'vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type ModalOptions = {
	userData: string;
	header: string;
	message: string;
	cancelLabel: string;
	confirmParams: Record<string, string>;
};

const { options } = defineProps<{
	options: ModalOptions;
}>();

const emit = defineEmits<{
	'close-modal': [];
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const rewardsStore = useRewardsStore();
const loading = ref(false);

const handleProceed = async () => {
	try {
		loading.value = true;
		await rewardsStore.claim(options.confirmParams);
		emit('close-modal');
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<div class="confirm-paypal-email-modal">
		<ModalCommonContent>
			<template #header>{{ $t(options.header) }}</template>
			<template #default>
				<div class="modal-content">
					<PrimeText tag="div" size="sm" weight="600" class="paypal-email">
						{{ options.userData }}
					</PrimeText>
					<PrimeText tag="div" color="disabled" size="sm">
						{{ $t(options.message) }}
					</PrimeText>
				</div>
			</template>
			<template #footer>
				<div class="actions-wrapper">
					<PrimeButton
						rounded="md"
						type="text"
						variant="grey"
						class="change-email-button"
						:label="$t(options.cancelLabel)"
						@click="handleCloseModal"
					/>
					<PrimeButton
						type="text"
						variant="grey"
						:label="$t(PROCEED)"
						@click="handleProceed"
					/>
				</div>
			</template>
		</ModalCommonContent>
		<PrimeLoader v-if="loading" />
	</div>
</template>

<style lang="scss" scoped>
.confirm-paypal-email-modal {
	:deep(.modal-content) {
		width: 100%;
		& .paypal-email {
			margin-bottom: 0.5rem;
		}
	}

	:deep(.content-footer) {
		& .change-email-button {
			color: var(--p-grey-700);
			margin-right: 0.5rem;
		}
	}
}
</style>
