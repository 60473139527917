<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { renderPrimeSurveys, integrationScriptLoading, unmountPrimeSurveys } =
	usePrimeSurveysIntegration();

onMounted(() => {
	renderPrimeSurveys('#support-page', 'support');
});

onBeforeUnmount(() => {
	unmountPrimeSurveys();
});
</script>

<template>
	<main class="integrated-page">
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<div id="support-page" class="integrated-container" />
	</main>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.integrated-page {
	width: 100%;

	:deep(.p-loader-spinner) {
		height: 100%;
	}
}

.integrated-container {
	display: flex;
	flex-direction: column;
	max-width: 34.375rem;
	margin: 0 auto;
	border-radius: 1.5rem;
	height: auto;
	min-height: 50%;
	justify-content: center;

	@include breakpoint(mobile) {
		background-color: var(--p-white);
	}

	:deep(.common-container) {
		max-width: none;
	}

	:deep(.common-content-scrollable) {
		max-height: 100vh;
	}
}
</style>
