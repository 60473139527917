<script setup lang="ts">
import {
	CONFIRM_PASSWORD,
	ENTER_NEW_PASSWORD,
	RESET_PASSWORD,
} from '@/constants/locales';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import { useResetPasswordView } from '@/views/reset-password/composables';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	form,
	getValidationFieldText,
	getValidationFieldStatus,
	handleChangePassword,
	isLoading,
} = useResetPasswordView();
</script>

<template>
	<AuthPageContent :back-button-visible="false" :title="$t(RESET_PASSWORD)">
		<form class="register-form" @submit.prevent="handleChangePassword">
			<PrimeInput
				v-model="form.password"
				type="password"
				:placeholder="$t(ENTER_NEW_PASSWORD)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>
			<PrimeInput
				v-model="form.password_confirmation"
				type="password"
				:placeholder="$t(CONFIRM_PASSWORD)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('password_confirmation')"
				:error-text="
					getValidationFieldText('password_confirmation')?.toString()
				"
			/>
			<div class="actions-wrapper">
				<PrimeButton
					variant="secondary"
					role="submit"
					full-width
					class="submit-register-button"
					:label="$t(RESET_PASSWORD)"
				/>
			</div>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.register-form {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	flex: 1;

	& .actions-wrapper {
		flex: 1;
		align-items: flex-end;
		display: flex;
	}
}
</style>
