<script setup lang="ts">
import {
	CONTINUE,
	EMAIL,
	PASSWORD,
	SIGN_UP,
	SIGN_UP_INFO,
} from '@/constants/locales';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import AcceptPolicy from '@/components/auth/AcceptPolicy.vue';
import { useSignupView } from '@/views/signup/composables';
import {
	PrimeText,
	PrimeButton,
	PrimeInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleSubmitForm,
	form,
	getValidationFieldText,
	getValidationFieldStatus,
	isLoading,
} = useSignupView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_UP)">
		<form class="register-form" @submit.prevent="handleSubmitForm">
			<PrimeText class="info-message">
				{{ $t(SIGN_UP_INFO) }}
			</PrimeText>

			<PrimeInput
				v-model="form.email"
				:placeholder="$t(EMAIL)"
				:float-placeholder="true"
				disabled
				readonly
				autocomplete="username"
				type="email"
			/>
			<PrimeInput
				v-model="form.password"
				autofocus
				autocomplete="password"
				type="password"
				:placeholder="$t(PASSWORD)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>

			<AcceptPolicy ref="acceptPolicyEl" />

			<div class="action-wrapper">
				<PrimeButton
					role="submit"
					variant="secondary"
					class="submit-register-button"
					:label="$t(CONTINUE)"
				/>
			</div>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.register-form {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	flex: 1;

	& .action-wrapper {
		flex: 1 1 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	& .info-message {
		padding: 0.75rem 0.625rem;
		text-align: center;
		background-color: var(--p-grey-200);
		border-radius: var(--p-radius-sm);
		margin-bottom: 1.5rem;
	}
}
</style>
