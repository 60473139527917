<script lang="ts" setup>
import { ref, watch } from 'vue';
import fallbackImage from '@/assets/icons/gift-duotone-thin.svg';

const {
	src,
	alt,
	size = 'medium',
	transparent = false,
} = defineProps<{
	src: string | null;
	alt: string;
	size?: 'small' | 'medium' | 'large';
	transparent?: boolean;
}>();

const logoUrl = ref(fallbackImage);

watch(
	() => src,
	(value) => {
		if (value) {
			logoUrl.value = value;
		}
	},
	{ immediate: true }
);
</script>

<template>
	<img
		class="reward-icon"
		:class="[size, { transparent }]"
		:src="logoUrl"
		:alt="alt"
		@error="logoUrl = fallbackImage"
	/>
</template>

<style lang="scss" scoped>
.reward-icon {
	flex: 0 0 auto;
	object-fit: contain;
	background-color: var(--p-white);
	border-radius: 0.5rem;

	&.transparent {
		background-color: transparent;
	}

	&.medium {
		width: 2.75rem;
		height: 1.75rem;
	}

	&.large {
		width: 4.75rem;
		height: 3rem;
	}
}
</style>
