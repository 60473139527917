import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useComponentName } from '@/utils/composables/useComponentName';
import { onBeforeUnmount, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { sendErrorInfo } from '@/utils/errorCatching';

type RegisterForm = { email: string; password: string };

export const useSignupView = (): {
	isLoading: Ref<boolean>;
	form: Ref<RegisterForm>;
	isHidePassword: Ref<boolean>;
	acceptPolicyEl: Ref<{
		policyValidationReset: () => void;
		policyValidate: () => Promise<boolean>;
		policyValidationError: ComputedRef<boolean>;
	} | null>;
	getValidationFieldStatus: (
		field: keyof RegisterForm
	) => 'error' | null | undefined;
	getValidationFieldText: (
		field: keyof RegisterForm
	) => string | null | undefined;
	handleSubmitForm: () => Promise<void>;
} => {
	const authStore = useAuthStore();
	const { authUserEmail, authErrors } = storeToRefs(authStore);
	const componentName = useComponentName();

	const isLoading = ref(false);
	const form = ref<RegisterForm>({
		email: authUserEmail.value,
		password: '',
	} as RegisterForm);
	const serverValidationResults = ref();
	const isHidePassword = ref(true);
	const acceptPolicyEl = ref<{
		policyValidationReset: () => void;
		policyValidate: () => Promise<boolean>;
		policyValidationError: ComputedRef<boolean>;
	} | null>(null);

	const { email, password } = useValidationRules();

	const validation = useVuelidate({ email, password }, form, {
		$autoDirty: false,
		$externalResults: serverValidationResults,
	});

	const {
		reset,
		clear,
		validate,
		isValidationError,
		getValidationFieldStatus,
		getValidationFieldText,
	} = useValidationUtils<RegisterForm>(validation);

	const clearForm = (): void => {
		form.value.email = '';
		form.value.password = '';
		reset();
		acceptPolicyEl.value?.policyValidationReset();
	};

	const handleSubmitForm = async (): Promise<void> => {
		clear();
		await validate();
		await acceptPolicyEl.value?.policyValidate();

		if (
			isValidationError.value ||
			acceptPolicyEl.value?.policyValidationError
		) {
			return;
		}

		try {
			isLoading.value = true;
			await authStore.register(form.value);
		} catch (error) {
			await sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	onBeforeUnmount(() => {
		clearForm();
	});

	watch(authErrors, (newErrors) => {
		serverValidationResults.value = { email: newErrors?.message };
	});

	return {
		isLoading,
		form,
		isHidePassword,
		acceptPolicyEl,
		getValidationFieldStatus,
		getValidationFieldText,
		handleSubmitForm,
	};
};
