<script setup lang="ts">
import { CONTINUE, EMAIL, SIGN_UP, SIGN_UP_SOCIAL } from '@/constants/locales';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import AcceptPolicy from '@/components/auth/AcceptPolicy.vue';
import { useSignupSocialView } from '@/views/signup-social/composables';
import SocialProviderIcon from '@/components/auth/SocialProviderIcon.vue';
import {
	PrimeButton,
	PrimeInput,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleSubmitForm,
	userSocialAuthData,
	form,
	isLoading,
	getValidationFieldStatus,
	getValidationFieldText,
} = useSignupSocialView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_UP)">
		<form class="social-register-form" @submit.prevent="handleSubmitForm">
			<div v-if="userSocialAuthData" class="form-title">
				<SocialProviderIcon :name="userSocialAuthData?.social_auth_provider" />
				<PrimeText size="sm">
					{{ $t(SIGN_UP_SOCIAL[userSocialAuthData?.social_auth_provider]) }}
				</PrimeText>
			</div>
			<PrimeInput
				v-model="form.email"
				:disabled="!!userSocialAuthData?.email"
				:placeholder="$t(EMAIL)"
				:float-placeholder="true"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
				autocomplete="username"
				type="email"
			/>

			<AcceptPolicy ref="acceptPolicyEl" />

			<div class="auth-actions-wrapper">
				<PrimeButton
					role="submit"
					variant="secondary"
					full-width
					:label="$t(CONTINUE)"
				/>
			</div>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.social-register-form {
	display: flex;
	flex-direction: column;
	flex: 1;

	& .form-title {
		display: flex;
		gap: 0.75rem;
		align-items: center;
		margin-bottom: 0.75rem;
		background: var(--p-grey-300);
		padding: 0.75rem 0.625rem;
		border-radius: var(--p-radius-sm);
	}
}
</style>
