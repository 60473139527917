import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export const initSentry = (app: App<Element>, router: Router) => {
	const sentryOptions = {
		app,
		dsn: Capacitor.isNativePlatform()
			? import.meta.env.VITE_SENTRY_URL_MOBILE
			: import.meta.env.VITE_SENTRY_URL_FRONTEND,
		integrations: [
			new SentryVue.BrowserTracing({
				routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
			}),
		],
		// @ts-ignore
		release: __RELEASE_NAME__,
		allowedUrls: [
			'api.primesurveys.com',
			'api.primesurveysapp.dev',
			'app.fivesurveys.com',
			'app.fivesurveysapp.dev',
		],
		// TODO PO
		// @ts-ignore
		beforeSend(event, hint) {
			if (hint && hint.originalException && hint.originalException.config) {
				const { url } = hint.originalException.config;
				if (url && !url.includes('fivesurveys')) {
					return null;
				}
			}
			return event;
		},

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost'],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,

		environment: import.meta.env.MODE,
		ignoreErrors: [
			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
			// Generic error code from errors outside the security sandbox
			// You can delete this if using raven.js > 1.0, which ignores these automatically.
			'Script error.',
			// Avast extension error
			'_avast_submit',
			'Failed to load the JS script of the agent',
			'request canceled with timeout',
		],
		denyUrls: [
			// Google Adsense
			'https://pagead2.googlesyndication.com',
			/pagead\/js/i,
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

			/api\.fpjs\.io/i,
			/pagead2\.googlesyndication\.com/i,
			/prod\.rtymgt\.com/i,
			/localhost/i,
		],
	};

	SentryVue.init(sentryOptions);
	if (Capacitor.isNativePlatform()) {
		Sentry.init(sentryOptions);
	}
};
