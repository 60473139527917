export class Timer {
	remaining: number;
	protected _timer?: NodeJS.Timeout;
	_step = 100;

	constructor(duration: number, public onFinish: () => void) {
		this.remaining = duration;
		this.create();
	}

	create() {
		this._timer = setInterval(() => {
			this.remaining -= this._step;
			if (this.remaining < 0) {
				this.onFinish();
			}
		}, this._step);
	}
}
