<script lang="ts" setup>
import { ACCEPT_5S_LEGAL } from '@/constants/locales';
import { getRootDomain } from '@/utils/helpers';
import { ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import {
	PrimeCheckbox,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const homePageURL = getRootDomain(true);

const legalRequirements = ref<{ policyAccepted: boolean }>({
	policyAccepted: false,
});

const { policyAccepted } = useValidationRules();

const legalRequirementsValidation = useVuelidate(
	{ policyAccepted },
	legalRequirements
);

const {
	reset: policyValidationReset,
	validate: policyValidate,
	isValidationError: policyValidationError,
} = useValidationUtils<{ policyAccepted: boolean }>(
	legalRequirementsValidation
);

defineExpose({
	policyValidationReset,
	policyValidate,
	policyValidationError,
});
</script>

<template>
	<div class="accept-policy">
		<PrimeCheckbox
			v-model="legalRequirements.policyAccepted"
			:invalid="policyValidationError"
		>
			<PrimeText
				size="sm"
				:color="policyValidationError ? 'critical' : 'grey-700'"
				v-html="
					$t(ACCEPT_5S_LEGAL, {
						privacyLink: `${homePageURL}/policy`,
						termsLink: `${homePageURL}/terms`,
					})
				"
			/>
		</PrimeCheckbox>
	</div>
</template>

<style lang="scss" scoped>
.accept-policy {
	display: flex;
	text-align: left;
	margin-top: 1.125rem;

	:deep(a.legal-link) {
		color: var(--p-grey-600);
		text-decoration: none;

		&:visited,
		&:active,
		&:hover {
			color: var(--p-grey-700);
		}

		&:hover {
			text-decoration: underline;
		}
	}

	:deep(label) {
		color: green;

		:deep(span) {
			color: orange;
		}
	}
}
</style>
