<script setup lang="ts">
import {
	NO_CONNECTION_MESSAGE,
	NO_CONNECTION_TITLE,
	RELOAD,
} from '@/constants/locales';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
import { PiWifiOff } from '@primeinsightsgroupllc/prime-icons';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { Network } from '@capacitor/network';

const { isMobile } = useScreenSize();

const handleReloadPage = async () => {
	const status = await Network.getStatus();
	if (status.connected) {
		document.location.reload();
	}
};
</script>

<template>
	<ErrorWrapper>
		<template #icon>
			<PiWifiOff :size="isMobile ? '2rem' : '2.75rem'" />
		</template>
		<template #title>
			{{ $t(NO_CONNECTION_TITLE) }}
		</template>
		<template #message>
			{{ $t(NO_CONNECTION_MESSAGE) }}
		</template>
		<template #actions>
			<PrimeButton
				variant="secondary"
				full-width
				:label="$t(RELOAD)"
				@click="handleReloadPage"
			/>
		</template>
	</ErrorWrapper>
</template>
