<script setup lang="ts">
import PageCard from '@/components/common/PageCard.vue';
import { onMounted, ref, shallowRef, watch } from 'vue';
import {
	PiUsersGroupFill,
	PiWalletFilled,
} from '@primeinsightsgroupllc/prime-icons';
import { transformCurrency } from '@/utils/helpers';
import { REF_STATS_EARNED, REF_STATS_FRIENDS } from '@/constants/locales';
import type { ReferralStatsItem } from '@/types';
import ReferralsStatsItem from '@/views/referrals/components/ReferralsStatsItem.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { getReferralStatistic } from '@/api';

const { currency } = storeToRefs(useUserStore());

const earnings = ref(0);
const isLoading = ref(true);

const stats = ref<Record<string, ReferralStatsItem>>({
	money_earned: {
		icon: shallowRef(PiWalletFilled),
		value: 0,
		text: REF_STATS_EARNED,
	},
	registered_friends: {
		icon: shallowRef(PiUsersGroupFill),
		value: 0,
		text: REF_STATS_FRIENDS,
	},
});

onMounted(async () => {
	const { money_earned, registered_friends } = await getReferralStatistic();
	earnings.value = money_earned || 0;
	stats.value.registered_friends.value = registered_friends || 0;
	isLoading.value = false;
});

watch([currency, earnings], ([currencyValue, earningsValue]) => {
	if (currencyValue && earningsValue) {
		stats.value.money_earned.value = `${transformCurrency(earningsValue, currencyValue, 2)}`;
	}
});
</script>

<template>
	<PageCard>
		<PrimeLoaderSpinner v-if="isLoading" />
		<div v-else class="referrals-stats__wrapper">
			<ReferralsStatsItem
				v-for="stat in stats"
				:key="stat.text"
				:stats-item="stat"
			/>
		</div>
	</PageCard>
</template>

<style scoped lang="scss">
.referrals-stats__wrapper {
	display: flex;
	justify-content: center;
	gap: 0.875rem;
}
</style>
