<script lang="ts" setup>
import { notifications } from './index';
import PrimeNotification from './PrimeNotification.vue';

document.addEventListener('keyup', (event) => {
	if (event.code === 'Escape') {
		notifications.value = [];
	}
});
</script>

<template>
	<div class="notification-container">
		<TransitionGroup name="group-slide">
			<PrimeNotification
				v-for="item in notifications"
				:key="item.id"
				:body="item.body"
				:icon="item.icon"
				class="group-slide__item"
				@close="item.onClose"
			/>
		</TransitionGroup>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';
.notification-container {
	position: fixed;
	bottom: 0;
	max-height: calc(100vh - 20%);
	width: 19.375rem;
	z-index: 10000;
	margin: 0 auto 1.125rem;

	@include breakpoint(mobile) {
		right: 1.5rem;
	}
}

.group-slide-enter-active,
.group-slide-leave-active {
	transition: all 0.2s linear;
	position: absolute;
}

.group-slide-enter-from,
.group-slide-leave-to {
	opacity: 0;
	transform: translateY(5rem);
}

.group-slide__item {
	transition: all 0.2s linear;
}
</style>
