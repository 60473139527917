<script lang="ts" setup>
import type { ProfileSettingsItem } from '@/types';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineEmits<{
	(e: 'click'): void;
}>();

const { item } = defineProps<{
	item: ProfileSettingsItem;
}>();

const { pushModal } = useModalStorage();

const showModal = () => {
	if (item.modal) {
		pushModal(item.modal);
	}
};

const onClick = item.handler || showModal;
</script>

<template>
	<div class="settings-item">
		<PrimeButton
			type="text"
			variant="grey"
			rounded="md"
			:label="item.title"
			full-width
			:data-test="item.test"
			@click="onClick"
		>
			<template #iconLeft>
				<component :is="item.icon" v-if="item.icon" />
			</template>
			<template #icon>
				<component :is="item.iconEnd" v-if="item.iconEnd" />
			</template>
		</PrimeButton>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.settings-item {
	border-bottom: 1px solid var(--p-grey-50);
	margin-bottom: 1.125rem;
	padding-bottom: 0.75rem;

	&:last-child {
		border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
	}

	:deep(.p-btn) {
		padding: 0.375rem;
		text-decoration: none;

		.p-btn-icon {
			&--left {
				margin-right: 0.625rem;
			}

			&--right {
				margin-left: auto;
				width: 1.375rem !important;
			}
		}

		.p-btn-label {
			font-weight: 400;
			color: var(--p-grey-800);
		}
	}
}
</style>
