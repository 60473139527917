<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { fetchTotalRewardsStats } from '@/api';
import type { TotalClaims } from '@/types';
import {
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { transformCurrency } from '@/utils/helpers';
import { TOTAL_CLAIMS } from '@/constants/locales';

const totalClaims = ref<TotalClaims | null>(null);
const isLoading = ref(true);

onBeforeMount(async () => {
	totalClaims.value = await fetchTotalRewardsStats();
	isLoading.value = false;
});

const amountOfClaims = computed(() => {
	return transformCurrency(
		+totalClaims.value?.money_value! || 0,
		totalClaims.value?.currency || 'USD',
		2
	);
});
</script>

<template>
	<div class="total-claims__container">
		<PrimeLoaderSpinner v-if="isLoading" />
		<div v-else class="total-claims__content">
			<PrimeText size="sm">{{ $t(TOTAL_CLAIMS) }}</PrimeText>
			<PrimeText size="sm" class="total-claims__amount" weight="600">
				{{ amountOfClaims }}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.total-claims__container {
	background-color: var(--p-grey-250);
	padding: 0.75rem 0.8125rem;
	box-shadow: var(--5s-shadow-card);
	border-radius: var(--p-radius-lg);
}

.total-claims__content {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	flex-wrap: wrap;
}
</style>
