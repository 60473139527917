import SurveysView from '@/views/surveys/SurveysView.vue';
import SignatureLoginView from '@/views/signature-login/SignatureLoginView.vue';
import ProfileView from '@/views/profile/ProfileView.vue';
import HistoryView from '@/views/history/HistoryView.vue';
import ConfirmClaimView from '@/views/confirm-claim/ConfirmClaimView.vue';
import SupportView from '@/views/support/SupportView.vue';
import EmailSubscriptionsView from '@/views/email-subscriptions/EmailSubscriptionsView.vue';
import ReferralsView from '@/views/referrals/ReferralsView.vue';
import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkContextIdHook,
	checkHistoryTypeHook,
	checkInitializedClaim,
} from '@/router/hooks';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: SurveysView,
		meta: {
			progress: true,
		},
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/signature-login',
		name: USER_ROUTE_NAME.SIGNATURE_LOGIN,
		component: SignatureLoginView,
		meta: {
			ignoreAuth: true,
		},
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: ProfileView,
		meta: {
			progress: true,
		},
	},
	{
		path: '/reward-history/:type?',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: HistoryView,
		meta: {
			title: true,
		},
		beforeEnter: checkHistoryTypeHook,
	},
	{
		path: '/claim-reward',
		name: USER_ROUTE_NAME.CLAIM_REWARD,
		component: ConfirmClaimView,
		meta: {
			title: true,
		},
		beforeEnter: checkInitializedClaim,
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: SupportView,
		meta: {
			progress: true,
		},
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		component: EmailSubscriptionsView,
		meta: {
			title: true,
			ignoreAuth: true,
		},
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: ReferralsView,
		meta: {
			progress: true,
		},
	},
];
