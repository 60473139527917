<script lang="ts" setup>
import { useNavDesktopItems } from '@/components/common/header/composables';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
const { items } = useNavDesktopItems();
</script>

<template>
	<nav class="desktop-nav">
		<RouterLink
			v-for="link in items.filter((item) => item.active)"
			:key="link.to"
			:to="link.to"
			:data-test="`header-navigation-${link.test}`"
			class="desktop-nav-link"
		>
			<component :is="link.icon" size="1.5rem" color="inherit" />
			<PrimeText size="sm" color="grey-300">{{ link.label }}</PrimeText>
		</RouterLink>
	</nav>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.desktop-nav {
	position: static;
	z-index: 1;
	display: none;
	margin: 0 auto;
	gap: 1.25rem;

	@include breakpoint(tablet) {
		position: absolute;
		left: 50%;
		display: flex;
		gap: 2.5rem;
		transform: translateX(-50%);
	}

	@include breakpoint(laptop) {
		gap: 4rem;
	}
}

.desktop-nav-link {
	position: relative;
	display: inline-flex;
	align-items: center;
	gap: 0.25rem;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	color: var(--p-grey-300);

	&:hover,
	&.router-link-active {
		&:before {
			opacity: 1;
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 0.125rem;
		opacity: 0;
		transition: opacity 0.25s ease-in;
		background-color: var(--p-secondary-base);
	}
}
</style>
