import { useComponentName } from '@/utils/composables/useComponentName';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import type { Ref } from 'vue';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import type { AxiosError } from 'axios';
import type { ChangePasswordForm, ErrorResponse } from '@/types';
import { sendErrorInfo } from '@/utils/errorCatching';

export const useResetPasswordView = (): {
	form: Ref<ChangePasswordForm>;
	isPasswordHidden: Ref<boolean>;
	isPasswordConfirmationHidden: Ref<boolean>;
	isLoading: Ref<boolean>;
	getValidationFieldStatus: (field: string) => string;
	getValidationFieldText: (field: string) => string;
	handleChangePassword: () => Promise<void>;
} => {
	const componentName = useComponentName();
	const route = useRoute();
	const authStore = useAuthStore();

	const form = ref<ChangePasswordForm>({} as ChangePasswordForm);

	const isLoading = ref(false);
	const isPasswordHidden = ref(true);
	const isPasswordConfirmationHidden = ref(true);
	const serverValidationResults = ref();
	const requestToken = ref('');
	const userEmail = ref('');

	const { password, password_confirmation } = useValidationRules();

	const passwordValue = computed(() => form.value.password);

	const validation = useVuelidate(
		{ password, password_confirmation: password_confirmation(passwordValue) },
		form,
		{ $autoDirty: false, $externalResults: serverValidationResults }
	);

	const {
		reset,
		clear,
		validate,
		isValidationError,
		getValidationFieldStatus,
		getValidationFieldText,
	} = useValidationUtils<ChangePasswordForm>(validation);

	onMounted(() => {
		if (route.query.email && route.query.token) {
			const { email, token } = route.query;
			if (email && token) {
				userEmail.value = email.toString();
				requestToken.value = token.toString();
			}
		}
	});

	const handleChangePassword = async () => {
		clear();
		await validate();

		if (isValidationError.value) return;

		try {
			isLoading.value = true;
			await authStore.resetPassword({
				email: userEmail.value,
				token: requestToken.value,
				password: form.value.password,
				password_confirmation: form.value.password_confirmation,
			});
		} catch (error) {
			const errorData = (error as AxiosError).response?.data as ErrorResponse;
			serverValidationResults.value = errorData.errors;
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	onBeforeMount(() => {
		form.value.password = '';
		form.value.password_confirmation = '';
		reset();
	});

	return {
		form,
		isPasswordHidden,
		isPasswordConfirmationHidden,
		isLoading,
		getValidationFieldStatus,
		getValidationFieldText,
		handleChangePassword,
	};
};
