import { useRewardsStore } from '@/stores/rewards';
import { storeToRefs } from 'pinia';
import { Component, computed, type ComputedRef } from 'vue';
import { InternalRewards } from '@/enums';
import ConfirmClaimDataPayPal from '@/views/confirm-claim/components/ConfirmClaimDataPayPal.vue';
import ConfirmClaimDataRevolut from '@/views/confirm-claim/components/ConfirmClaimDataRevolut.vue';
import ConfirmClaimDefault from '@/views/confirm-claim/components/ConfirmClaimDefault.vue';

const confirmClaimComponents: Record<string, Component> = {
	[InternalRewards.PAYPAL]: ConfirmClaimDataPayPal,
	[InternalRewards.REVOLUT]: ConfirmClaimDataRevolut,
	default: ConfirmClaimDefault,
};

export const useConfirmClaimView = (): {
	isClaimAvailable: ComputedRef<boolean>;
	component: ComputedRef<Component>;
} => {
	const { currentSelectedReward } = storeToRefs(useRewardsStore());

	const component = computed(() => {
		if (currentSelectedReward.value) {
			return (
				confirmClaimComponents[
					currentSelectedReward.value.reward_external_id
				] || confirmClaimComponents['default']
			);
		} else {
			return confirmClaimComponents['default'];
		}
	});

	return {
		component,
	};
};
