<script lang="ts" setup>
import { onMounted, onBeforeMount } from 'vue';
import { type LocationQuery, useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import { Echo } from '@/utils/echo';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { signatureQueryStorage } from '@/utils/local-storage';

const route = useRoute();
const router = useRouter();
const componentName = useComponentName();

const authStore = useAuthStore();

onBeforeMount(async () => {
	const echo = Echo.getInstance();
	echo.removeConnections();
});

const isQueryUsed = (query: LocationQuery): boolean => {
	const isUsed = signatureQueryStorage.value === JSON.stringify(query);
	if (isUsed) {
		signatureQueryStorage.value = null;
	} else {
		signatureQueryStorage.value = JSON.stringify(query);
	}
	return isUsed;
};

onMounted(async () => {
	if (!isQueryUsed(route.query)) {
		try {
			// Login as admin
			if (route.query && route.query.auth_token && route.query.auth_email) {
				const { auth_token: token, auth_email: email } = route.query;
				await authStore.loginWithToken(token.toString(), email.toString());
				// Login with onetime token
			} else if (route.query && route.query.token_auth_url) {
				const authUrl = route.query.token_auth_url.toString();
				await authStore.loginWithOneTimeToken(authUrl);
				// Login from one click survey and survey reminder
			} else if (route.query && route.query.signature_auth_url) {
				const signatureAuthUrl = route.query.signature_auth_url.toString();
				await authStore.loginWithOneTimeToken(signatureAuthUrl);
			} else {
				await router.replace({
					name: 'app-login',
				});
			}
		} catch (error) {
			sendErrorInfo(error, componentName);
		}
	} else {
		await router.replace({
			name: 'surveys',
		});
	}
});
</script>

<template>
	<div class="login-page">
		<div class="page-content">
			<PrimeLoaderSpinner />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.login-page {
	display: flex;
	flex-direction: column;
	max-width: 73.125rem;
	width: 100%;
	margin: 0 1.1875rem;
	justify-content: center;
}

.page-content {
	flex: 1 0 auto;
	display: flex;
	align-items: center;
}
</style>
