<script lang="ts" setup>
import {
	EMAIL,
	CONTINUE,
	LOGIN_TITLE,
	LOGIN_SUB_TITLE,
	OR,
	LOGIN_SIGNUP,
} from '@/constants/locales';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import SocialButton from '@/components/auth/SocialButton.vue';
import cards from '@/assets/cards.svg';
import { useAppLoginView } from '@/views/app-login/composables';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { formattedCurrency } from '@/utils/helpers';
import {
	PrimeText,
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	form,
	handleSubmitForm,
	getValidationFieldStatus,
	getValidationFieldText,
	loading,
	socialAuthButtons,
	handleSocialAuth,
	handleLoading,
	amount,
	payout,
	currencyName,
} = useAppLoginView();

const { isMobile } = useScreenSize();
</script>

<template>
	<div class="login-page">
		<div class="page-top">
			<div class="login-container">
				<div class="header-wrapper">
					<PrimeText tag="h1" weight="700" align="center" class="page-title">
						{{
							$t(LOGIN_TITLE, {
								amount: formattedCurrency(payout, currencyName, 0),
							})
						}}
					</PrimeText>
					<PrimeText
						tag="p"
						align="center"
						:size="isMobile ? 'lg' : 'xl'"
						color="grey-700"
						class="page-teaser"
						v-html="
							$t(LOGIN_SUB_TITLE, {
								amount: formattedCurrency(amount, currencyName, 0),
							})
						"
					/>
				</div>
			</div>
			<div class="page-cards">
				<img :src="cards" alt="cards" />
			</div>
		</div>
		<div class="page-bottom">
			<div class="login-container">
				<PrimeText
					tag="p"
					weight="500"
					align="center"
					class="form-title"
					:size="isMobile ? 'sm' : 'base'"
				>
					{{ $t(LOGIN_SIGNUP) }}
				</PrimeText>
				<form @submit.prevent="handleSubmitForm">
					<PrimeInput
						v-model="form.email"
						:placeholder="$t(EMAIL)"
						:float-placeholder="true"
						:invalid="!!getValidationFieldStatus('email')"
						:error-text="getValidationFieldText('email')?.toString()"
						autocomplete="username"
						type="email"
					/>

					<PrimeButton
						variant="secondary"
						full-width
						role="submit"
						class="submit-button"
						:disabled="loading"
						:label="$t(CONTINUE)"
					/>
				</form>

				<div class="social-login__title">
					<div class="title-divider" />
					<PrimeText tag="p" class="title-text" size="sm">
						{{ $t(OR) }}
					</PrimeText>
					<div class="title-divider" />
				</div>
				<div class="providers-container">
					<SocialButton
						v-for="provider in socialAuthButtons"
						:key="provider"
						:provider="provider"
						white
						small
						@loading="handleLoading($event)"
						@auth="handleSocialAuth($event)"
					/>
				</div>
			</div>
		</div>
		<PrimeLoader v-if="loading" full-page />
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.login-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 0 1.25rem;

	@include breakpoint(mobile) {
		padding: 0 0 1.5rem;
	}
}

.login-container {
	width: 100%;
	max-width: 33.75rem;
	margin: 0.75rem auto 0;
	padding: 0 1.125rem;

	.page-bottom & {
		margin-top: 0;
	}

	@include breakpoint(mobile) {
		padding: 0;
	}
}

.page-title.p-text {
	font-family: var(--p-secondary-font-family);
	white-space: pre-line;
	font-size: calc(12px + 1.5vh);

	@include breakpoint(mobile) {
		font-size: 2.625rem;
	}
}

.page-teaser {
	margin-top: 0.375rem;
	:deep(span) {
		padding: 0.25rem;
		background: var(--p-secondary-base);
		border-radius: 0.25rem;
		color: var(--p-grey-950);
		font-weight: 700;
	}
}

.page-top {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background: #fff;
	border-bottom-left-radius: 2.25rem;
	border-bottom-right-radius: 2.25rem;
	box-shadow: 0px 4px 44px 0px #00000014;
	padding-bottom: 3.75rem;

	.login-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@include breakpoint(mobile) {
		padding-bottom: 7.438rem;
	}
}
.page-bottom {
	flex: 2.5;
	padding-top: 5.625rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include breakpoint(mobile) {
		padding-top: 10.5rem;
	}
}
.page-cards {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
	z-index: 2;
	margin-bottom: -1rem;

	img {
		display: block;
		height: 8.25rem;
		width: auto;
		max-width: unset;
	}

	@include breakpoint(mobile) {
		margin-bottom: -1.5rem;
		img {
			display: block;
			height: 14.25rem;
			max-width: unset;
		}
	}
}
@media (min-width: 767px) and (max-height: 800px) {
	.page-cards {
		margin-bottom: 0;
	}
	.page-cards img {
		height: 7rem;
	}
	.page-bottom {
		padding-top: 3rem;
	}
	.page-top {
		padding-bottom: 4rem;
	}

	.page-title.p-text {
		font-size: calc(12px + 1.5vh);
	}
}

.header-wrapper {
	text-align: center;
}

.form-title {
	margin-bottom: 0.5rem;
	@include breakpoint(mobile) {
		margin-bottom: 0.75rem;
	}
}

.submit-button {
	margin-top: 1rem;
}

.social-login {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.social-login__title {
	display: flex;
	align-items: center;
	margin: 0.5rem 0;

	& .title-divider {
		height: 1px;
		flex: 1 1 auto;
		background-color: var(--p-grey-225);
	}

	& .title-text {
		margin: 0 0.675rem;
		color: #202223;
	}

	@include breakpoint(mobile) {
		& {
			margin: 1.5rem 0 1.25rem;
		}
	}
}

.providers-container {
	display: flex;
	gap: 0.75rem;

	:deep(.p-btn) {
		flex: 1;
	}
}

.footer-wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin: 1.5rem 0;
	text-align: center;
	flex: 1;

	& :deep(.legal-link) {
		color: var(--p-grey-600);
		text-decoration: underline;

		@include breakpoint(mobile) {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
</style>
