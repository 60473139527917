<script lang="ts" setup>
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import LocalizationListItem from '@/views/app-login/components/LocalizationListItem.vue';
import {
	LANGUAGE_COUNTRY,
	LOCALIZATION_EMPTY_SEARCH,
	OTHER_OPTIONS,
	SAVE,
	SEARCH_COUNTRY,
	SELECT_COUNTRY_LANGUAGE_HEADER,
} from '@/constants/locales';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import InputSearch from '@/components/common/InputSearch.vue';
import { useLocalizationView } from '@/views/localization/composables';

const {
	localization,
	handleSelectLocalization,
	filteredCountries,
	selectedLocalization,
	country,
	searchQuery,
	saveSelectedLanguage,
} = useLocalizationView();
</script>

<template>
	<AuthPageContent :title="$t(LANGUAGE_COUNTRY)">
		<div class="localizations-wrapper">
			<PrimeText tag="p" class="text-left" size="sm" weight="600">{{
				$t(SELECT_COUNTRY_LANGUAGE_HEADER)
			}}</PrimeText>
			<div class="localizations-container">
				<LocalizationListItem
					is-selected
					:country="country"
					:selected-language="localization"
					@select-localization="handleSelectLocalization($event)"
				/>

				<div class="localizations-options-wrapper">
					<PrimeText tag="div" size="xs" weight="600" class="content-subtitle">
						{{ $t(OTHER_OPTIONS) }}
					</PrimeText>

					<InputSearch
						v-model:search="searchQuery"
						:placeholder="$t(SEARCH_COUNTRY)"
					/>
					<div class="countries-list-container">
						<template v-if="filteredCountries.length">
							<LocalizationListItem
								v-for="item in filteredCountries"
								:key="item"
								:country="item"
								:selected-language="selectedLocalization"
								@select-localization="handleSelectLocalization($event)"
							/>
						</template>

						<template v-else>
							<div class="empty-list-fallback">
								<PrimeText class="fallback-message">
									{{ $t(LOCALIZATION_EMPTY_SEARCH) }} {{ searchQuery }}
								</PrimeText>
							</div>
						</template>
					</div>
				</div>
			</div>
			<PrimeButton
				variant="secondary"
				full-width
				:disabled="!selectedLocalization"
				:label="$t(SAVE)"
				@click="saveSelectedLanguage"
			/>
		</div>
	</AuthPageContent>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.localizations-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.localizations-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: auto;
	flex: 1;

	& :deep(.prime-input) {
		margin-bottom: 1.125rem;
	}
}
.content-subtitle {
	margin-bottom: 0.75rem;
	text-align: start;
}

.localizations-options-wrapper {
	border-radius: var(--p-radius-2xl);
	padding: 0.875rem 1rem;
	background-color: var(--p-white);
}

.countries-list-container {
	min-height: 30vh;
	max-height: 30vh;
	width: 100%;
	overflow-y: scroll;
}
</style>
