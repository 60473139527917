import { ref, onMounted, onBeforeUnmount } from 'vue';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import {
	ClaimStatuses,
	PostMessages,
	SignatureActions,
	TrackingEvents,
} from '@/enums';
import { useRewardsStore } from '@/stores/rewards';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { CONFIRM_CLAIM_MODAL, REWARD_SENT_MODAL } from '@/constants/modals';
import { signatureLoginActionStorage } from '@/utils/local-storage';
import { usePostMessageHandler } from '@/utils/composables/usePostMessageHandler';
import { RateApp } from 'capacitor-rate-app';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { randomizeChance } from '@/utils/helpers';
import { useAppStore } from '@/stores/app';

export const useSurveysView = () => {
	const { integrationScriptLoading, renderPrimeSurveys, unmountPrimeSurveys } =
		usePrimeSurveysIntegration();

	const RELOAD_ATTEMPTS: number = 3;
	const CHECK_SCRIPT_INTERVAL_SECONDS: number = 15;

	const scriptLoadError = ref(false);
	const reloadCounter = ref(0);
	const interval = ref<any>(null);

	const checkScriptRender = (): void => {
		interval.value = setInterval(() => {
			if (reloadCounter.value > RELOAD_ATTEMPTS) {
				// Show SurveysFallback
				scriptLoadError.value = true;
				clearInterval(interval.value);
				return;
			}

			if (document.querySelector('.integration-script-container')) {
				clearInterval(interval.value);
			} else {
				reloadCounter.value++;
				unmountPrimeSurveys();
				renderPrimeSurveys('#prime-survey');
			}
		}, CHECK_SCRIPT_INTERVAL_SECONDS * 1000);
	};

	onMounted(() => {
		renderPrimeSurveys('#prime-survey');

		// Check and Reload script with some interval
		checkScriptRender();
	});

	onBeforeUnmount(() => {
		clearInterval(interval.value);
		unmountPrimeSurveys();
	});

	const rewardsStore = useRewardsStore();
	const modalStorage = useModalStorage();
	const { isMobileApp } = useMobileApp();
	const { appReviewProbability } = storeToRefs(useUserStore());
	const appStore = useAppStore();

	onMounted(async () => {
		if (
			rewardsStore.claimStatus === ClaimStatuses.SUCCESS ||
			signatureLoginActionStorage.value ===
				SignatureActions.CLAIM_USER_CONFIRMATION
		) {
			await modalStorage.pushModal({ name: REWARD_SENT_MODAL });
			rewardsStore.setClaimStatus(null);
			signatureLoginActionStorage.value = null;
		}

		if (rewardsStore.claimStatus === ClaimStatuses.USER_CONFIRMATION) {
			await modalStorage.pushModal({ name: CONFIRM_CLAIM_MODAL });
			rewardsStore.setClaimStatus(null);
		}
	});

	usePostMessageHandler(async (event: MessageEvent) => {
		if (event.data.message === PostMessages.QUALIFICATION_IN_PROGRESS) {
			appStore.setQualificationInProgress(event.data.qualification_in_progress);
		}

		if (event.data.message === PostMessages.SURVEY_STARTED) {
			await appStore.trackEvent(TrackingEvents.SURVEY_STARTED, {
				survey_id: event.data.survey_id,
			});
		}

		if (event.data.message === PostMessages.SURVEY_COMPLETED) {
			// Based on appReviewProbability randomly ask for review
			if (isMobileApp) {
				const askForReviewChance: boolean = randomizeChance(
					appReviewProbability.value
				);

				if (askForReviewChance) {
					RateApp.requestReview();
				}
			}

			await appStore.trackEvent(TrackingEvents.SURVEY_COMPLETED, {
				survey_id: event.data.survey_id,
				survey_points: event.data.survey_points,
				survey_count: event.data.user_completes_count,
			});
		}
	});

	const rerenderSurveys = () => {
		unmountPrimeSurveys();
		renderPrimeSurveys('#prime-survey');
	};

	return {
		integrationScriptLoading,
		scriptLoadError,
		rerenderSurveys,
	};
};
