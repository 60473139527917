<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { type RouteLocationRaw, useRouter } from 'vue-router';
import { PiArrowLeft } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { useAuthStore } from '@/stores/auth';

const { backRoute = 'app-login' } = defineProps<{
	title: string;
	backButtonVisible?: boolean;
	backRoute?: RouteLocationRaw;
}>();

const router = useRouter();
const authStore = useAuthStore();

onBeforeMount(() => {
	authStore.setTopNavigationVisibility(false);
});

onBeforeUnmount(() => {
	authStore.setTopNavigationVisibility(true);
});

const goBack = () => {
	if (backRoute) {
		router.push(backRoute);
	} else {
		router.go(-1);
	}
};
</script>

<template>
	<div class="content-wrapper">
		<div class="auth-page-header">
			<PrimeButton variant="grey" type="link" rounded="rounded" @click="goBack">
				<template #icon><PiArrowLeft /></template>
			</PrimeButton>
			<PrimeText tag="h2" size="2xl">{{ $t(title) }}</PrimeText>
		</div>
		<div class="auth-page-content" :class="[$attrs.class]">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.content-wrapper {
	padding-bottom: env(safe-area-inset-bottom);
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	max-width: 73.125rem;
}

.auth-page-content {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1 0 auto;
	width: 100%;
	max-width: 26.875rem;
	margin: 0 auto;
	text-align: center;
	padding: 0.75rem;
}

.auth-page-header {
	padding: 0 1rem;
	margin-bottom: 1.75rem;
	height: 2.5rem;
	display: flex;
	gap: 1rem;
	align-items: center;
}
</style>
