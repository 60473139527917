import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { authRoutes } from '@/router/routes/auth';
import { userRoutes } from '@/router/routes/user';
import { utilityRoutes } from '@/router/routes/utility';
import { localeGuard, authGuard } from '@/router/guards';

const router = createRouter({
	history: createWebHistory(),
	routes: [...authRoutes, ...userRoutes, ...utilityRoutes],
});

router.afterEach((to) => {
	if (to.name) {
		const appStore = useAppStore();
		appStore.setCurrentScreen(to.name as any).then(() => {});
	}
});

router.beforeEach(async (to, _from, next) => {
	await localeGuard(to, _from, next);
	await authGuard(to, _from, next);
});

export default router;
