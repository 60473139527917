<script setup lang="ts">
import PageCard from "@/components/common/PageCard.vue";
import ReferralsStep from "@/views/referrals/components/ReferralsStep.vue";
import { PrimeText } from "@primeinsightsgroupllc-ui/prime-ui-kit";
import {
	HOW_IT_WORKS,
	REF_STEP_1_DESCRIPTION,
	REF_STEP_1_TITLE,
	REF_STEP_2_DESCRIPTION,
	REF_STEP_2_TITLE,
	REF_STEP_3_DESCRIPTION,
	REF_STEP_3_TITLE
} from "@/constants/locales";

const steps: {title: string, description: string}[] = [
	{
		title: REF_STEP_1_TITLE,
		description: REF_STEP_1_DESCRIPTION,
	},
	{
		title: REF_STEP_2_TITLE,
		description: REF_STEP_2_DESCRIPTION,
	},
	{
		title: REF_STEP_3_TITLE,
		description: REF_STEP_3_DESCRIPTION,
	},
];
</script>

<template>
	<PageCard>
		<PrimeText tag="h2" size="xl" weight="600" align="center">
			{{ $t(HOW_IT_WORKS) }}
		</PrimeText>
		<div class="referrals-steps">
			<ReferralsStep
				v-for="(step, index) in steps"
				:key="index"
				:number="index + 1"
				:title="$t(step.title)"
				:description="$t(step.description)"
			/>
		</div>
	</PageCard>
</template>

<style lang="scss" scoped>
.referrals-steps {
	margin-top: 1.125rem;
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
}
</style>
