<script lang="ts" setup></script>

<template>
	<div class="skeleton" />
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

@keyframes shine {
	from {
		background-position: 0;
	}
	to {
		background-position: 500px;
	}
}

.skeleton {
	background-image: linear-gradient(
		90deg,
		#f1f1f1 0px,
		#f5f5f5 40px,
		#f1f1f1 80px
	);
	margin: 0.5rem 0;
	border-radius: 0.25rem;
	background-size: 31.25rem;
	animation: shine 1s infinite;

	@include breakpoint(mobile) {
		margin: 0.75rem 0;
	}
}
</style>
