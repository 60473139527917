<script setup lang="ts">
import becomeRichIcon from '@/assets/icons/become-rich.svg';
import { CONGRATS, YOU_CAN_CLAIM } from '@/constants/locales';
import { transformCurrency } from '@/utils/helpers';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import RewardImage from '@/components/common/RewardImage.vue';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';

const { currentSelectedReward } = storeToRefs(useRewardsStore());
</script>

<template>
	<img :src="becomeRichIcon" alt="Become rich icon" />
	<PrimeText tag="h2" weight="600" size="xl" class="title">
		{{ $t(CONGRATS) }}
	</PrimeText>
	<PrimeText
		tag="p"
		weight="500"
		size="xs"
		color="grey-500"
		class="text subtitle"
	>
		{{ $t(YOU_CAN_CLAIM) }}
	</PrimeText>
	<div class="reward">
		<RewardImage
			:src="currentSelectedReward?.image_url || ''"
			:alt="currentSelectedReward?.name || 'logo'"
			size="large"
		/>
		<PrimeText weight="500" size="lg" class="money-value">
			{{
				transformCurrency(
					currentSelectedReward?.money_value,
					currentSelectedReward?.currency
				)
			}}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.text {
	text-align: center;

	&.subtitle {
		margin-bottom: 1.375rem;
	}

	&.balance-info {
		max-width: 60%;
		margin-bottom: 1.5rem;

		@include breakpoint(mobile) {
			margin-bottom: 2.75rem;
		}
	}
}

.reward {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-bottom: 0.75rem;

	.coins-value {
		margin-left: auto;
	}

	.money-value {
		margin-top: 0.875rem;
	}
}
</style>
