<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineProps<{
	label: string;
	data: string;
}>();
</script>

<template>
	<div :class="[$attrs.class]">
		<PrimeText tag="div" size="sm" color="disabled">
			{{ $t(label) }}:&nbsp;
		</PrimeText>
		<PrimeText tag="div" size="sm" weight="600">
			{{ data }}
		</PrimeText>
	</div>
</template>
