<script setup lang="ts">
import { DATE, BALANCE, AMOUNT } from '@/constants/locales';
import { getTransactionContent } from '@/views/history/views/transactions/components/transactions';
import { toDate, transformCurrency } from '@/utils/helpers';
import type { Transaction } from '@/types';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import ListItemElement from '@/views/history/views/transactions/components/ListItemElement.vue';
import ActionStatus from '@/components/ActionStatus.vue';

const { transaction } = defineProps<{
	transaction: Transaction;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';
</script>

<template>
	<div class="transaction-item">
		<ListItemElement
			class="transaction-coins"
			:data="
				transformCurrency(transaction.money_value, transaction.currency, 2)
			"
			:label="AMOUNT"
		/>

		<div class="transaction-type">
			<ActionStatus :content="getTransactionContent(transaction.type)" />
		</div>

		<ListItemElement
			class="transaction-date"
			:data="toDate(transaction.date, localization)"
			:label="DATE"
		/>

		<ListItemElement
			class="transaction-balance"
			:data="transformCurrency(transaction.balance, transaction.currency, 2)"
			:label="BALANCE"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.transaction-item {
	display: grid;
	grid-template-columns: 2fr 2fr;
	padding: 0.75rem;
	margin-bottom: 0.75rem;
	border-radius: 1rem;
	background: var(--p-white);
	box-shadow: var(--5s-shadow-card);

	.transaction-coins,
	.transaction-balance,
	.transaction-date,
	.transaction-type {
		display: flex;
		align-items: center;
	}

	.transaction-balance,
	.transaction-date {
		margin-top: 0.625rem;
	}

	.transaction-balance {
		justify-content: flex-end;
	}

	.transaction-type {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: end;
	}
}
</style>
