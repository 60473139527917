<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import SurveysFallback from '@/views/surveys/components/SurveysFallback.vue';
import { useAuthStore } from '@/stores/auth';
import { useSurveysView } from '@/views/surveys/composables';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import SurveysListTop from '@/views/surveys/components/SurveysListTop.vue';
import { computed } from 'vue';

const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);
const userStore = useUserStore();
const { isHalfSurveysUnlocked } = storeToRefs(userStore);
const { integrationScriptLoading, scriptLoadError, rerenderSurveys } =
	useSurveysView();

const showHalfSurveysToggler = computed(() => {
	return (
		!integrationScriptLoading.value &&
		isHalfSurveysUnlocked.value &&
		import.meta.env.VITE_HALF_SURVEY_TOGGLER === 'true'
	);
});
</script>

<template>
	<main v-if="!userStore.error && !scriptLoadError" class="surveys__container">
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<SurveysListTop
			v-else-if="showHalfSurveysToggler"
			@toggle-half-surveys="rerenderSurveys"
		/>
		<div
			v-if="isAuthorized"
			id="prime-survey"
			:class="{ 'prime-survey-application': !integrationScriptLoading }"
		/>
	</main>
	<SurveysFallback v-else />
	<RouterView />
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.surveys__container {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;

	:deep(.notice-content b.text-red) {
		color: var(--p-primary-base);
	}

	:deep(.surveys-wrapper) {
		background: none;
	}

	:deep(.common-title) {
		background: transparent;
	}
}

.prime-survey-application {
	flex: 1 1 auto;
}

.rewards-section {
	width: 100%;
	padding: 0.875rem 1.125rem 0.375rem;

	@include breakpoint(mobile) {
		padding: 1.5rem 1.5rem 0.375rem;
	}
}
</style>
