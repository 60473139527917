<script lang="ts" setup>
import {
	MOBILE_APP_HERE_MODAL_TITLE,
	MOBILE_APP_HERE_TITLE,
	MOBILE_APP_HERE_TEXT,
	MOBILE_APP_HERE_MESSAGE_1,
	MOBILE_APP_HERE_MESSAGE_2,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import mobilePhone from '@/assets/icons/mobile-app.svg';
import appStore from '@/assets/app-store.svg';
import googlePlay from '@/assets/google-play.svg';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
const { VITE_APP_STORE_DOWNLOAD_URL, VITE_GOOGLE_PLAY_DOWNLOAD_URL } =
	import.meta.env;

const platforms = [
	{
		name: 'app-store',
		iconSrc: appStore,
		url: VITE_APP_STORE_DOWNLOAD_URL,
		iconWidth: '140',
	},
	{
		name: 'google-play',
		iconSrc: googlePlay,
		url: VITE_GOOGLE_PLAY_DOWNLOAD_URL,
		iconWidth: '140',
	},
];
</script>

<template>
	<ModalCommonContent class="mobile-app-here-modal text-center">
		<template #header>{{ $t(MOBILE_APP_HERE_MODAL_TITLE) }}</template>
		<template #icon>
			<img :src="mobilePhone" alt="" />
		</template>
		<template #title>
			<PrimeText size="lg" >
				{{ $t(MOBILE_APP_HERE_TITLE) }}
			</PrimeText>
		</template>
		<template #message>
			<PrimeText color="grey-700">
				{{ $t(MOBILE_APP_HERE_TEXT) }}
			</PrimeText>
		</template>
	</ModalCommonContent>
	<div class="download-app">
		<a
			v-for="platform in platforms"
			:key="platform.name"
			class="download-app__button"
			:href="platform.url"
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				:src="platform.iconSrc"
				:alt="platform.name"
				:width="platform.iconWidth"
				height="32"
			/>
		</a>
	</div>
	<PrimeText size="sm" tag="div" color="grey-700" align="center" class="mobile-app-here-description">
		{{ $t(MOBILE_APP_HERE_MESSAGE_1) }} {{ $t(MOBILE_APP_HERE_MESSAGE_2) }}
	</PrimeText>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.mobile-app-here-modal {
	:deep(.content-icon) {
		width: 12.5rem;
		height: 12.5rem;
	}

	:deep(.content-title) {
		margin-bottom: 0;
	}
}

.download-app {
	display: flex;
	justify-content: center;
	gap: 0.75rem;
	margin: 1.5rem 0;

	&__button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0.375rem;
		border-radius: 0.3125rem;
		border: 1px solid var(--p-grey-600);
		background-color: var(--p-black);
	}
}

.mobile-app-here-description {
	max-width: 85%;
	text-align: center;
	margin: 0 auto;
}
</style>
