import { defineStore, storeToRefs } from 'pinia';
import type {
	ClaimedReward,
	ErrorResponse,
	Rewards,
	SelectedReward,
} from '@/types';
import {
	claimReward,
	getRewards,
	getRewardsHistory,
	selectReward,
} from '@/api';
import type { AxiosError } from 'axios';
import { ClaimErrorStatuses, ClaimStatuses, TrackingEvents } from '@/enums';
import { sortRewards } from '@/utils/helpers';
import { sendErrorInfo } from '@/utils/errorCatching';
import { notify } from '@/components/common/notifications';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';

interface RewardsState {
	rewards: Rewards | null;
	claimedRewards: ClaimedReward[] | null;
	claimStatus: string | null;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	selectedReward: SelectedReward | null;
	meta: RewardsMeta | null;
}

export const useRewardsStore = defineStore('rewards', {
	state: (): RewardsState => ({
		rewards: null,
		claimedRewards: null,
		claimStatus: null,
		loading: false,
		initLoading: false,
		error: null,
		selectedReward: null,
		meta: null,
	}),

	getters: {
		rewardByCategories: (state) => state.rewards,
		isInitialLoading: (state) => state.initLoading,
		isLoading: (state) => state.loading,
		rewardsError: (state) => state.error?.message || '',
		rewardsMeta: (state) => state.meta,
		currentSelectedReward: (state) => state.selectedReward,
	},

	actions: {
		async fetchRewards(): Promise<void> {
			try {
				this.initLoading = true;
				const { rewards, meta } = await getRewards();
				this.meta = meta;
				this.rewards = sortRewards(rewards);
			} catch (error) {
				this.error = (error as AxiosError).response?.data as ErrorResponse;
				sendErrorInfo(error);
			} finally {
				this.initLoading = false;
			}
		},

		async selectReward(optionId: number): Promise<number | undefined> {
			try {
				this.loading = true;
				const { status, selected_reward, message } =
					await selectReward(optionId);
				this.setSelectedReward(selected_reward);
				notify({ body: message });
				return status;
			} catch (_error) {
				const error = _error as AxiosError;
				this.error = error.response?.data as ErrorResponse;
				sendErrorInfo(error);
				return error.response?.status;
			} finally {
				this.loading = false;
			}
		},

		async fetchRewardsHistory(): Promise<void> {
			try {
				this.loading = true;
				this.claimedRewards = await getRewardsHistory();
			} catch (error) {
				this.error = (error as AxiosError).response?.data as ErrorResponse;
				sendErrorInfo(error);
			} finally {
				this.loading = false;
			}
		},

		setClaimStatus(value: string | null) {
			this.claimStatus = value;
		},

		async claim(
			params?: Record<string, string>
		): Promise<{ askConfirm: boolean; message: string; status: number }> {
			try {
				this.loading = true;

				const appStore = useAppStore();

				const { message, status } = await claimReward(params);

				this.setClaimStatus(
					status === 200 ? ClaimStatuses.SUCCESS : ClaimStatuses.FAILED
				);

				if (this.claimStatus === ClaimStatuses.SUCCESS) {
					await appStore.trackEvent(TrackingEvents.CLAIM_REQUESTED, {
						amount: this.selectedReward?.coin_value.toString() || '',
					});
					const userStore = useUserStore();

					await userStore.fetchUserData();

					notify({ body: message });

					await router.push({ name: 'surveys' });
				}

				return { askConfirm: false, message, status };
			} catch (error) {
				sendErrorInfo(error);
				const { response } = error as AxiosError;
				const { status, data } = response!;
				const { message, errors } = data as ErrorResponse;
				const appStore = useAppStore();

				if (
					[
						ClaimErrorStatuses.NEW_CLAIM_DATA_USED,
						ClaimErrorStatuses.NOT_VALID_DATA_USED,
					].includes(status)
				) {
					return { askConfirm: true, message, status };
				} else if (status === ClaimErrorStatuses.CONFIRM_CLAIM_VIA_EMAIL) {
					this.setClaimStatus(ClaimStatuses.USER_CONFIRMATION);
					await appStore.trackEvent(TrackingEvents.CLAIM_REQUESTED, {
						amount: this.selectedReward?.coin_value.toString() || '',
					});
					await router.push({ name: 'surveys' });
				} else {
					this.setClaimStatus(ClaimStatuses.FAILED);
					notify({
						body: errors ? Object.values(errors || []).join(' ') : message,
					});
				}
				return { askConfirm: false, message, status };
			} finally {
				this.loading = false;
			}
		},

		setSelectedReward(value: SelectedReward | null): void {
			this.selectedReward = value;
		},
	},
});
