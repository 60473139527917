<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { notify } from '@/components/common/notifications';
import {
	REF_COPY_LINK,
	REF_COPY_WARNING,
	REF_LINK_COPIED,
} from '@/constants/locales';
import { shallowRef } from 'vue';
import {
	PiCheckCircleFill,
	PiCopy,
	PiWarningCircle,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const { refLink } = storeToRefs(useUserStore());
const { t } = useI18n();

const copyToClipboard = async () => {
	await navigator.clipboard.writeText(refLink.value);
	notify({
		body: t(REF_LINK_COPIED),
		icon: shallowRef(PiCheckCircleFill),
	});
};
</script>

<template>
	<PrimeButton
		full-width
		variant="secondary"
		class="referrals-view__copy-button"
		:label="$t(REF_COPY_LINK)"
		@click="copyToClipboard"
	>
		<template #iconLeft>
			<PiCopy />
		</template>
	</PrimeButton>
	<div class="referrals-view__warning">
		<PiWarningCircle color="inherit" size="1.25rem" />
		<PrimeText tag="p" size="xs" color="inherit">
			{{ $t(REF_COPY_WARNING) }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
button.referrals-view__copy-button {
	max-width: 34.375rem;
}

.referrals-view__warning {
	display: flex;
	align-items: center;
	color: var(--p-text-important);
	gap: 0.25rem;
	width: 100%;
	max-width: 34.375rem;
}
</style>
