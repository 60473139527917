import { useCookies } from 'vue3-cookies';
import { getRootDomain } from '@/utils/helpers';
import { Cookies, Platforms } from '@/enums';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { platformStorage } from '@/utils/local-storage';

export const useAppCookies = (): {
	setMarketingId: (id: string) => void;
	marketingId: string | null;
	setLocalizationCookie: (value: string) => void;
	localizationCookie: string | null;
	setAuthToken: (token: string) => void;
	removeAuthToken: () => void;
	authToken: string;
} => {
	const { cookies } = useCookies();
	const { isMobileApp } = useMobileApp();

	const localizationCookie = cookies.get(Cookies.LOCALIZATION) || null;
	const marketingId = cookies.get(Cookies.M_ID) || null;
	const authToken = cookies.get(Cookies.AUTH_TOKEN) || '';

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	let domain = getRootDomain();
	if (isMobileApp) {
		domain = '';
	}
	const THIRTY_DAYS = 60 * 60 * 24 * 30; // 30 days
	const SIX_MONTHS = THIRTY_DAYS * 6;
	const ONE_YEAR = 60 * 60 * 24 * 365;

	const setCookie = (
		name: string,
		value: string,
		period: number = THIRTY_DAYS
	) => {
		if (isSafari) {
			const expireDate = new Date(Date.now() + period * 1000).toUTCString();
			document.cookie = `${name}=${value}; path=/; domain=${domain}; expires=${expireDate};`;
		} else {
			cookies.set(name, value, period, '/', domain);
		}
	};

	const setAuthToken = (token: string): void => {
		cookies.remove(Cookies.AUTH_TOKEN);
		setCookie(
			Cookies.AUTH_TOKEN,
			token,
			isMobileApp ? SIX_MONTHS : THIRTY_DAYS
		);
	};

	const removeAuthToken = () => {
		if (platformStorage.value === Platforms.IOS) {
			setAuthToken('');
		} else {
			cookies.remove(Cookies.AUTH_TOKEN, '/', domain);
		}
	};

	const setMarketingId = (id: string): void => {
		cookies.remove(Cookies.M_ID);
		setCookie(Cookies.M_ID, id, ONE_YEAR);
	};

	const setLocalizationCookie = (value: string): void => {
		cookies.remove(Cookies.LOCALIZATION);
		setCookie(Cookies.LOCALIZATION, value.toLowerCase(), ONE_YEAR);
	};

	return {
		setAuthToken,
		setMarketingId,
		setLocalizationCookie,
		localizationCookie,
		authToken,
		marketingId,
		removeAuthToken,
	};
};
