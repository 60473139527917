<script setup lang="ts">
import {
	PrimeButton,
	PrimeText,
	PrimeToggle,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiCircleAndHalf } from '@primeinsightsgroupllc/prime-icons';
import { LEARN_MORE, SHOW_HALF_SURVEYS, SURVEYS } from '@/constants/locales';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { HALF_SURVEYS_INFO_MODAL } from '@/constants/modals';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { changeHalfSurveys } from '@/api';

const emit = defineEmits<{
	'toggle-half-surveys': [];
}>();

const { pushModal } = useModalStorage();
const userStore = useUserStore();
const { isHalfSurveysEnabled } = storeToRefs(userStore);

const isSelected = ref(true);
const isLoading = ref(false);

const toggleHalfSurveys = async (value: string): Promise<void> => {
	try {
		isLoading.value = true;
		const { status } = await changeHalfSurveys(value === 'true');
		if (status === 200) {
			await userStore.fetchUserMeta();
			emit('toggle-half-surveys');
		}
	} catch (error) {
		console.error(error);
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
	<div class="surveys-list-top__container">
		<PrimeText weight="500" size="lg">{{ $t(SURVEYS) }}</PrimeText>
		<div class="half-survey-toggle__wrapper">
			<PrimeToggle
				v-model="isSelected"
				:checked="isHalfSurveysEnabled"
				:disabled="isLoading"
				@input="toggleHalfSurveys($event.target.value)"
			/>
			<PrimeText size="sm">{{ $t(SHOW_HALF_SURVEYS) }}</PrimeText>
			<div class="half-survey-icon__wrapper">
				<PiCircleAndHalf color="inherit" size="1rem" />
			</div>
			<PrimeButton
				type="link"
				variant="grey"
				size="small"
				class="half-surveys-info__button"
				:label="$t(LEARN_MORE)"
				@click="
					pushModal({
						name: HALF_SURVEYS_INFO_MODAL,
					})
				"
			/>
		</div>
	</div>
</template>

<style lang="scss">
.surveys-list-top__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 64rem;
	margin: 0 auto;
	width: 100%;
	flex-wrap: wrap;
	padding: 0.25rem 1rem;
	row-gap: 0.5rem;
}

.half-survey-toggle__wrapper {
	display: flex;
	align-items: center;
	line-height: 1;
	flex-wrap: wrap;
	row-gap: 0.5rem;

	& div.p-toggle-wrapper {
		margin-bottom: 0;
		margin-right: 0.5rem;
	}
}

.half-survey-icon__wrapper {
	margin-left: 0.25rem;
	margin-right: 1rem;
	color: var(--ps-secondary);
	flex-shrink: 0;
}

.half-surveys-info__button {
	&.p-btn--grey {
		color: var(--p-grey-700);
		font-weight: 400;
	}
}
</style>
