<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiCircleAndHalf } from '@primeinsightsgroupllc/prime-icons';
import {
	HALF_PROGRESS_MODAL_DESCRIPTION_1,
	HALF_PROGRESS_MODAL_DESCRIPTION_2,
	HALF_PROGRESS_MODAL_HEADER,
	HALF_PROGRESS_MODAL_MESSAGE,
	HALF_PROGRESS_MODAL_TITLE,
} from '@/constants/locales';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user';

defineEmits<{
	(e: 'close-modal'): void;
}>();

const userStore = useUserStore();

onMounted(async () => {
	await userStore.fetchUserMeta();
});
</script>

<template>
	<div id="half-progress-modal">
		<ModalCommonContent class="text-center">
			<template #header>
				{{ $t(HALF_PROGRESS_MODAL_HEADER) }}
			</template>
			<template #icon>
				<PiCircleAndHalf size="2.75rem" color="inherit" />
			</template>
			<template #title>
				<PrimeText size="lg" tag="p" weight="500">
					{{ $t(HALF_PROGRESS_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #description>
				<div>
					<PrimeText color="grey-700" size="sm">
						{{ $t(HALF_PROGRESS_MODAL_DESCRIPTION_1) }}
					</PrimeText>
					<br />
					<br />
					<PrimeText color="grey-700" size="sm">
						{{ $t(HALF_PROGRESS_MODAL_DESCRIPTION_2) }}
					</PrimeText>
				</div>
			</template>
			<template #message>
				{{ $t(HALF_PROGRESS_MODAL_MESSAGE) }}
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#half-progress-modal {
	:deep(.modal-common-content) {
		.content-icon {
			margin-bottom: 1.5rem;
			color: var(--ps-secondary);
		}

		.content-description {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}
}
</style>
