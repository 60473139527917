<script setup lang="ts">
import { SOMETHING_WENT_WRONG, TRY_AGAIN, REFRESH } from '@/constants/locales';
import { PiWarningCircleFill } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import ErrorWrapper from '@/components/ErrorWrapper.vue';

const { isMobile } = useScreenSize();

const handleRefreshPage = () => document.location.reload();
</script>

<template>
	<ErrorWrapper>
		<template #icon>
			<PiWarningCircleFill :size="isMobile ? '2rem' : '2.75rem'" />
		</template>
		<template #title>
			{{ $t(SOMETHING_WENT_WRONG) }}
		</template>
		<template #message>
			{{ $t(TRY_AGAIN) }}
		</template>
		<template #actions>
			<PrimeButton
				class="fallback-button"
				variant="secondary"
				:label="$t(REFRESH)"
				@click="handleRefreshPage"
			/>
		</template>
	</ErrorWrapper>
</template>
