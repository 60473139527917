<script setup lang="ts">
import TransactionsView from '@/views/history/views/transactions/TransactionsView.vue';
import RewardsView from '@/views/history/views/rewards/RewardsView.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { REWARDS, TRANSACTIONS } from '@/constants/locales';
import { useHistoryView } from '@/views/history/composables';

const { activeTabIndex, onTabChange } = useHistoryView();
</script>

<template>
	<main class="history">
		<TabView
			v-model:activeIndex="activeTabIndex"
			lazy
			@tab-change="onTabChange"
		>
			<TabPanel :header="$t(TRANSACTIONS)">
				<TransactionsView />
			</TabPanel>
			<TabPanel :header="$t(REWARDS)">
				<RewardsView />
			</TabPanel>
		</TabView>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.history {
	width: 100%;
	height: 100%;

	@include breakpoint(mobile) {
		width: 40rem;
		border-radius: 0.75rem 0.75rem 0 0;
	}
}
</style>
