<script setup lang="ts">
import {
	APP_REVIEW,
	APP_REVIEW_MESSAGE,
	APP_REVIEW_TITLE,
	OKAY,
} from '@/constants/locales';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { RateApp } from 'capacitor-rate-app';
import appReview from '@/assets/icons/app-review.svg';

const emits = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleClick = () => {
	RateApp.requestReview();
	emits('close-modal');
};
</script>

<template>
	<div id="app-review-modal">
		<ModalCommonContent class="text-center">
			<template #header>
				{{ $t(APP_REVIEW) }}
			</template>
			<template #icon>
				<img :src="appReview" alt="" />
			</template>
			<template #title>
				<PrimeText size="lg" weight="600">{{ $t(APP_REVIEW_TITLE) }}</PrimeText>
			</template>
			<template #message>{{ $t(APP_REVIEW_MESSAGE) }}</template>
			<template #footer>
				<PrimeButton
					type="text"
					variant="grey"
					rounded="md"
					:label="$t(OKAY)"
					@click="handleClick"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#app-review-modal {
	:deep(.modal-common-content) {
		.content-icon {
			width: 12.5rem;
			height: 12.5rem;
		}
	}
}
</style>
