<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { CURRENT_BALANCE } from '@/constants/locales';
import { transformCurrency } from '@/utils/helpers';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { currencyBalance, currency } = storeToRefs(useUserStore());

const balanceAmount = computed(() => {
	return `${transformCurrency(
		+currencyBalance.value! || 0,
		currency.value,
		2
	)}`;
});
</script>

<template>
	<div class="user-balance">
		<PrimeText size="sm" transform="capitalize" color="grey-300">
			{{ $t(CURRENT_BALANCE) }}
		</PrimeText>
		<PrimeText
			class="user-balance-value"
			size="xl"
			weight="700"
			color="grey-100"
		>
			{{ balanceAmount }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.user-balance {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;

	@include breakpoint(tablet) {
		align-items: flex-end;
	}
}
</style>
