import { onMounted, onUnmounted } from 'vue';
import type { Ref } from 'vue';

export const useClickOutside = (
	targetEl: Ref<HTMLElement>,
	callback: () => void
): { listener: (event: Event) => void } | void => {
	if (!targetEl) {
		return;
	}

	const listener = (event: Event) => {
		if (
			event.target === targetEl.value ||
			event.composedPath().includes(targetEl.value)
		) {
			return;
		}

		if (typeof callback === 'function') {
			callback();
		}
	};

	onMounted(() => {
		window.addEventListener('click', listener);
	});

	onUnmounted(() => {
		window.removeEventListener('click', listener);
	});

	return {
		listener,
	};
};
