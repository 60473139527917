import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppStore } from '@/stores/app';
import { getRootDomain } from '@/utils/helpers';
import { UTILITY_ROUTE_NAME } from '@/constants/routes';

export const utilityRoutes = [
	{
		path: '/logout',
		name: UTILITY_ROUTE_NAME.LOGOUT,
		redirect: () => {
			const authStore = useAuthStore();
			const { isMobileApp } = useMobileApp();
			authStore
				.removeToken()
				.then(() => {
					const appStore = useAppStore();
					return appStore.resetTracking();
				})
				.then(() => {
					authStore.setEmail('');
					if (!isMobileApp) {
						window.location.assign(getRootDomain(true));
					}
				});
			return isMobileApp ? '/' : '';
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: () => 'surveys',
	},
];
