<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useClickOutside } from '@/utils/composables/useClickOutside';
import { PiClose } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { TEST_MODAL_CLOSE } from '@/constants/dataTestAttrs';

const {
	show,
	useDefaultClose = true,
	position = null,
	dataTestId = '',
} = defineProps<{
	show: boolean;
	useDefaultClose?: boolean;
	position?: 'bottom' | null;
	dataTestId?: string;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const close = () => {
	emit('close-modal');
};

const modalContainerRef = ref();
const isModalDisplay = ref(false);
const timeout = ref<any>(null);

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'Escape') {
		close();
	}
};

watch(
	() => show,
	(value) => {
		if (!value && position === 'bottom') {
			// Set timeout for transition duration on close
			timeout.value = setTimeout(() => {
				isModalDisplay.value = value;
				clearTimeout(timeout.value);
			}, 550);
		} else {
			isModalDisplay.value = value;
		}
	},
	{ immediate: true }
);

onMounted(() => {
	document.addEventListener('keyup', handleKeyup);
	useClickOutside(modalContainerRef, close);
});

onUnmounted(() => {
	document.removeEventListener('keyup', handleKeyup);
});

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});
</script>

<template>
	<div v-if="isModalDisplay" class="modal-mask">
		<div
			ref="modalContainerRef"
			class="modal-container"
			:class="[$attrs.class, position, { show }]"
			:data-test-id="dataTestId"
		>
			<PrimeButton
				v-if="useDefaultClose"
				class="modal-close-button"
				variant="grey"
				size="small"
				rounded="rounded"
				:data-test="TEST_MODAL_CLOSE"
				:data-test-id="dataTestId"
				@click="close"
			>
				<template #icon>
					<PiClose size="100%" />
				</template>
			</PrimeButton>
			<div class="modal-content">
				<slot />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.modal-mask {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(12, 12, 12, 0.52);
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;

	@supports (inset: 0) {
		inset: 0;
	}

	@supports not (inset: 0) {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.modal-container {
	background-color: var(--p-white);
	position: relative;
	padding: 1.25rem 0.75rem 1.5rem;
	border-radius: var(--5s-radius-xl);
	max-width: 34.375rem;
	width: 95vw;
	overflow: hidden;

	@include breakpoint(mobile) {
		padding: 1.375rem 1.125rem 1.125rem;
	}

	&.bottom {
		align-self: flex-end;
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100vw;
		border-radius: 1.5rem 1.5rem 0 0;
		transform: translateY(100%);
		animation: modal-slide-down 0.45s;

		@include breakpoint(mobile) {
			padding: 1.5rem;
		}

		&.show {
			animation: modal-slide-up 0.45s forwards;
		}
	}

	.modal-close-button {
		position: absolute;
		top: 0.75rem;
		right: 1.125rem;
		z-index: 10;
	}
}

@keyframes modal-slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes modal-slide-down {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100%);
	}
}
</style>
