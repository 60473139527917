import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SURVEYS, ACCOUNT, HELP, REFERRALS } from '@/constants/locales';
import type { DesktopNavItem } from '@/types';
import {
	PiHome,
	PiHomeFill,
	PiUserCircle,
	PiUserCircleFill,
	PiQuestionCircle,
	PiQuestionCircleFill,
	PiUsersGroupAdd,
	PiUsersGroupAddFill,
} from '@primeinsightsgroupllc/prime-icons';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { REWARDS_MODAL } from '@/constants/modals';

export const useNavDesktopItems = () => {
	const { t } = useI18n();

	const items = computed<Array<DesktopNavItem>>(() => [
		{
			icon: PiHome,
			iconActive: PiHomeFill,
			label: t(SURVEYS),
			to: '/surveys',
			active: true,
			test: 'surveys',
		},
		{
			icon: PiUsersGroupAdd,
			iconActive: PiUsersGroupAddFill,
			label: t(REFERRALS),
			to: '/referrals',
			active: true,
			test: 'referrals',
		},
		{
			icon: PiUserCircle,
			iconActive: PiUserCircleFill,
			label: t(ACCOUNT),
			to: '/profile',
			active: true,
			test: 'profile',
		},
		{
			icon: PiQuestionCircle,
			iconActive: PiQuestionCircleFill,
			label: t(HELP),
			to: '/support',
			active: true,
			test: 'support',
		},
	]);

	return {
		items,
	};
};

export const useMainHeaderActions = () => {
	const modalStorage = useModalStorage();

	const openRewardsModal = async () => {
		await modalStorage.pushModal({
			name: REWARDS_MODAL,
			options: { position: 'bottom' },
		});
	};

	return {
		openRewardsModal,
	};
};
