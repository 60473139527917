<script lang="ts" setup>
import { ref } from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import ConfirmClaimWrapper from '@/views/confirm-claim/components/ConfirmClaimWrapper.vue';

const isLoading = ref(false);
const rewardsStore = useRewardsStore();

const handleConfirmClaim = async () => {
	try {
		isLoading.value = true;
		await rewardsStore.claim();
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
	<ConfirmClaimWrapper
		:disabled="false"
		:loading="isLoading"
		@confirm="handleConfirmClaim"
	/>
</template>

<style scoped lang="scss">
.claim-email-wrapper {
	margin-bottom: 1.5rem;
	width: 100%;
}
</style>
