{
	"sign_in": "Sign In",
	"save": "Save",
	"email": "Email",
	"password": "Password",
	"reset_password": "Reset Password",
	"confirm_email": "Confirm your email",
	"confirm_email_message": "Please click the link in the confirmation email.",
	"resend": "Resend",
	"email_required": "Email is required",
	"email_not_valid": "Email is not valid",
	"password_required": "Password is required",
	"password_min_length": "Password should be at least 8 characters long",
	"coins": "points",
	"select": "Select",
	"success": "Success",
	"balance": "Balance",
	"congrats": "Congrats!",
	"you_can_claim": "You can claim your reward",
	"reward_sent": "Your reward is on the way!",
	"okay": "Okay",
	"enter_new_password": "Enter new password",
	"confirm_password": "Confirm Password",
	"confirm": "Confirm",
	"confirm_password_required": "Password confirmation required",
	"updated_balance": "Your balance has been updated",
	"something_went_wrong": "Something went wrong",
	"try_again": "Please try again",
	"transactions": "Transactions",
	"transactions_empty_title": "No Transactions yet",
	"transactions_empty_description": "You don’t have any transaction yet",
	"select_rewards": "Select rewards",
	"search_rewards": "Search rewards...",
	"no_rewards_title": "No Rewards",
	"no_rewards_message": "No rewards at the moment",
	"claim_reward": "Claim Reward",
	"receive_reward_via_email": "You will receive your reward via email within 30 minutes",
	"transfer": "Transfer | Transfers",
	"prepaid_cards": "Prepaid Card | Prepaid Cards",
	"gift_cards": "Gift Card | Gift Cards",
	"charity": "Charity Donation | Charity Donations",
	"view_more_rewards": "View {value} more",
	"no_rewards_with_name": "There are no available rewards available for \"{value}\".",
	"surveys": "Surveys",
	"rewards": "Rewards",
	"reward": "Reward",
	"delete": "Delete",
	"delete_account": "Delete Account",
	"delete_account_question": "Are you sure you want to delete your account?",
	"delete_account_information": "Please enter password to delete account. This can not be undone",
	"no_rewards_history_message": "You did not withdraw any rewards yet",
	"collect_points": "Collect Points",
	"done": "Done",
	"processing": "Processing",
	"amount": "Amount",
	"date": "Date",
	"change_timezone": "Change Timezone",
	"change_email": " Change Email",
	"new_email": "New email",
	"your_password": "Your password",
	"change_password": "Change Password",
	"old_password": "Old Password",
	"logout": "Logout",
	"profile_surveys_completed": "Surveys Completed",
	"help": "Help",
	"no_more_entries": "No More Entries",
	"loading_more": "Loading More...",
	"other_transaction": "Other",
	"email_preferences": "E-mail Preferences",
	"email_subs_title": "Deactivate emails you would like to unsubscribe from",
	"email_subs_show_all": "Show all email preferences",
	"email_sub_offers": "Special Offers",
	"email_sub_reminders": "Reminders",
	"email_sub_weekly_report": "Weekly Report",
	"email_sub_account_updates": "Account Updates",
	"email_sub_survey_invitations": "Survey Invitations",
	"email_sub_offers_description": "Special offers for you to earn even more",
	"email_sub_reminders_description": "Reminders when you did not take surveys in  a while or missing out on a great offer",
	"email_sub_survey_invitations_description": "Weekly Invitations to the best and highest-paying surveys",
	"email_subs_updated": "Preferences has been successfully updated",
	"email_subs_not_updated": "Preferences updating error :(",
	"account": "Account",
	"more": "More",
	"need_new_password": "Need new password?",
	"unlink_socials": "Changing email will unlink all social accounts",
	"reset_password_sent_message": "We’ve emailed a reset password link to <span class='user-email'>{email}</span>",
	"reset_password_sent_message-details": "Please check your inbox and click on the link to reset your password",
	"reset_password_sent_message-spam": "If you don’t see the email in your inbox, check your spam folder",
	"language": "Language",
	"change_language_title": "Change Language",
	"change_language_subtitle": "Available Languages",
	"change_language_message_fix": "The selected language affects how you see Five Surveys and how we communicate with you",
	"select_country_language_header": "Select Country and Language",
	"search_country": "Search country",
	"localization_modal_no_items": "There are no available options for",
	"other_options": "Other options",
	"continue": "Continue",
	"login": "Log in",
	"sign_up": "Sign up",
	"sign_up_info": "We need your email address and password to save your progress and send you the rewards you redeem. Your data is not given to any third party.",
	"email_verification_warning": "Please click the link in the <span>confirmation email</span>",
	"sent": "Sent",
	"continue_with_provider": "Continue with ",
	"social_account_message": "Looks like you already have an account. </br> Please log in instead.",
	"not_your": "Not your? ",
	"use_another_account": "Use another account",
	"sign_up_google": "You are signing up with Gmail",
	"sign_up_facebook": "You are signing up with Facebook",
	"sign_up_apple": "You are signing up with Apple ID",
	"account_exists": "Account Exists",
	"link_account_message": "Looks like you already have an account. Please log in instead.",
	"set_password": "Set Password",
	"set_password_message": "Please set a password to log in to your account",
	"claim_email": "Please enter your {value} email",
	"no_connection_title": "No Internet Connection",
	"no_connection_message": "It appears that your device is currently not connected to the internet. Please check your network settings and try again.",
	"reload": "Reload",
	"mobile_app_here_modal_title_fs": "Five Surveys App!",
	"mobile_app_here_title": "Our Mobile App Is here",
	"mobile_app_here_text": "Take surveys on the go with our new mobile app.",
	"mobile_app_here_message_1": "All the same surveys, but from the convenience of your phone.",
	"mobile_app_here_message_2": "Available for iOS and Android.",
	"code_required": "Code is required",
	"code_sent": "Code sent!",
	"code_sent_message": "Please check your email and submit the verification code below.",
	"code_sent_question": "Didn’t get the email?",
	"resend_code": "Resend code",
	"not_verified_code": "Please enter the confirmation code we sent yo your email.",
	"confirm_paypal_email": "Confirm Email",
	"confirm_paypal_message": "You have not used this PayPal email before. Do you want to Proceed?",
	"proceed": "Proceed",
	"refunded": "Refunded",
	"error": "Error",
	"refund": "Refund",
	"password_not_equal": "The value must be equal to the password value",
	"select_language": "Select language", 
	"update_dialog_title": "Update Available",
	"update_dialog_message": "Version {version} is available. Would you like to update now?`",
	"app_review": "App Review",
	"app_review_title": "The app might soon ask you for a review",
	"app_review_message": "It would mean a lot to us if you could take a few minutes to give us a review. Thank you!",
	"email_to_previous": "We’ve sent an email to your previous email address. Please click the confirmation link in that email to confirm your changes.",
	"confirm_claim_modal_title": "Please confirm it's you!",
	"confirm_claim_modal_message": "Please check <b style='color: var(--text-default)'>{email}</b> for a confirmation link to approve this reward",
	"confirm_via_email": "Confirm via email",
	"login_title_3": "The quickest \n way to earn {amount}",
	"login_sub_title_2": "We pay out <span>{amount} +</span> every day",
	"login_signup": "Log In or Sign Up",
	"or": "OR",
	"accept_fs_legal": "I accept Five Surveys the <a href={privacyLink} target='_blank' rel='noopener noreferrer' class='legal-link'>Privacy Policy</a> and <a href={termsLink} target='_blank' rel='noopener noreferrer' class='legal-link'>Terms & Conditions</a>",
	"language_country": "Language & Country",
	"current_balance": "Current balance",
	"five_surveys_reward": "Complete 5 surveys and get {value}",
	"cash_earned": "Cash earned",
	"settings": "Settings",
	"timezone": "Timezone",
	"save_changes": "Save changes",
	"completed_5_surveys_modal_title": "Woohoo!!",
	"completed_5_surveys_modal_subtitle": "{value} was added to your balance! ",
	"completed_5_surveys_modal_message": "Congratulations! You've completed 5 surveys, which has boosted your balance by {value}.",
	"reward_sent_modal_header": "Reward is on the way!",
	"reward_sent_modal_message": "You will receive it via email within 5 minutes.",
	"great": "GREAT",
	"five_surveys_no_rewards_title": "No Payouts Yet",
	"transactions_rewards": "Transactions & Rewards",
	"refresh": "Refresh",
	"logout_question": "Are you sure you want to log out?",
	"updated_progress": "Your progress has been updated",
	"great_job": "Great job!",
	"first_complete": "1/5 Surveys completed",
	"complete_more_updated": "Complete <b>4 more</b> surveys to redeem {value}",
	"email_sub_weekly_report_description_undated": "Weekly updates about how much you earned on Five Surveys",
	"email_sub_account_updates_description_undated": "Updates about your account",
	"total_claims": "Lifetime Reward Claims:",
	"half_progress_modal_header": "Introducing Half-surveys.",
	"half_progress_modal_title": "Super simple surveys!",
	"half_progress_modal_description_1": "If you see this icon next to a survey card, it means that the survey is very easy to complete and contributes half a complete (0.5) to your progress bar.",
	"half_progress_modal_description_2": "Our clients pay less for these super-easy surveys. These are extra surveys you would normally not see, but we really don’t want you to miss out on them!",
	"half_progress_modal_message": "Completing 2 surveys with this icon earns you 1 complete on your progress bar.",
	"full_name": "Full Name",
	"tag": "Tag",
	"full_name_required": "Full name required",
	"tag_required": "Tag required",
	"full_name_min_length": "Full name too short",
	"full_name_max_length": "Full name too long",
	"tag_min_length": "Tag too short",
	"tag_max_length": "Tag too long",
	"revolut_claim_data": "Please enter your Revolut <span class='bold'>Full name</span> & <span class='bold'>Revolut tag</span>",
	"show_half_surveys": "Show Half Surveys",
	"learn_more": "Learn More",
	"what_is_half_surveys": "What are half-surveys?",
	"half_surveys_icon_description": "Surveys marked with {icon} increase your progress by 0.5 points.",
	"half_surveys_info_message_1": "Clients pay us very little for these surveys.",
	"half_surveys_info_message_2": "Many of these surveys are short and easy, so we still want to show them to you.",
	"half_surveys_info_message_3": "You can disable half surveys anytime.",
	"ask_permissions_modal_header": "Want to earn more?",
	"ask_permissions_modal_title": "Some surveys need additional data to track when you complete them.",
	"ask_permissions_modal_message": "Please give your permission to see more great surveys. After clicking “Select choice”, you will see a modal asking for permission.",
	"ask_permissions_modal_important": "You won't share other personal data by giving your permission",
	"select_choice": "Select choice",
	"referrals": "Referrals",
	"referral": "Referral",
	"how_it_works": "How It Works",
	"ref_step_1_title": "Invite your friends",
	"ref_step_1_description": "Tell your friends about Five Surveys and share your referral link with them.",
	"ref_step_2_title": "Your friends sign up",
	"ref_step_2_description": "Your friends join Five Surveys using your link.",
	"ref_step_3_title": "Earn 10% commission",
	"ref_step_3_description": "They keep their earnings, and you get 10% of their survey income!",
	"ref_stats_earned": "Money Earned",
	"ref_stats_friends": "Friends Signed Up",
	"ref_copy_link": "Copy Referral Link",
	"ref_copy_warning": "You can only have one account and you can’t refer yourself!",
	"ref_link_copied": "Referral link has been copied",
	"ref_notification_modal_header": "Earn more by Reffering!",
	"ref_notification_modal_title": "Earn passive income by referring your friends",
	"ref_notification_modal_message": "When someone signs up using your link, you earn a 10% commission on their earnings! Forever!",
	"ref_notification_modal_footer": "refer a friend",
	"new_version_available_header": "New version is available!",
	"new_version_available_title": "It’s time to update",
	"new_version_available_msg_store": "A brand new version of Five Surveys app is available in the {store}. Please update your app to use all of our amazing features.",
	"new_version_available_btn": "Update",
	"new_version_available_success": "Five Surveys was successfully updated",
	"new_version_available_title_downloading": "Update in progress...",
	"new_version_available_msg_downloading": "App is updating, please wait a bit",
	"new_version_available_title_installing": "Updates are being installed",
	"new_version_available_msg_installing": "Please wait a moment while we enhance your experience",
	"new_version_available_title_installed": "The updates have been installed",
	"new_version_available_msg_installed": "Please click the button below to restart and apply the changes",
	"new_version_available_btn_installed": "Apply changes",
	"new_version_available_title_failed": "The update could not be installed",
	"new_version_available_msg_failed": "Please update via the {store}",
	"new_version_available_btn_failed": "Go to {store}"
}
