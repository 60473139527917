<script setup lang="ts">
import PageCard from '@/components/common/PageCard.vue';
import ProfileHeader from '@/views/profile/components/ProfileHeader.vue';
import {
	PrimeText,
	PrimeLoaderSpinner,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { SETTINGS, MORE } from '@/constants/locales';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useProfileView } from '@/views/profile/composables';
import SettingsItem from '@/views/profile/components/SettingsItem.vue';
import AppMeta from '@/views/profile/components/AppMeta.vue';
import { useDev } from '@/utils/composables/useDev';

const { loading } = storeToRefs(useUserStore());
const { isDev } = useDev();

const {
	settingsConfig,
	moreSettingsConfig,
	profileStatistic,
	isProfileStatsLoaded,
} = useProfileView();
</script>

<template>
	<main class="profile">
		<PrimeLoaderSpinner v-if="loading" class="profile-loader" />
		<template v-else>
			<ProfileHeader
				:stats="profileStatistic"
				:is-loaded="isProfileStatsLoaded"
				class="profile-header"
			/>
			<div v-if="isDev" class="profile-settings">
				<AppMeta />
			</div>
			<div class="profile-settings">
				<PrimeText class="profile-title" tag="h2" size="lg" weight="500">
					{{ $t(SETTINGS) }}
				</PrimeText>
				<PageCard size="sm">
					<template v-for="item in settingsConfig" :key="item.title">
						<SettingsItem v-if="item.visible" :item="item" />
					</template>
				</PageCard>
			</div>
			<div class="profile-settings">
				<PrimeText class="profile-title" tag="h2" size="lg" weight="500">
					{{ $t(MORE) }}
				</PrimeText>
				<PageCard size="sm">
					<template v-for="item in moreSettingsConfig" :key="item.title">
						<SettingsItem v-if="item.visible" :item="item" />
					</template>
				</PageCard>
			</div>
		</template>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.profile {
	width: 100%;
}

.profile-loader {
	height: 100%;
}

.profile-header {
	margin-bottom: 1rem;
}

.profile-settings {
	margin: 0 auto 1.5rem;
	max-width: 34.375rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.profile-title {
	margin-bottom: 0.75rem;
}
</style>
