import API from '@/api';

interface CountryCode {
	code: string;
}

export const fetchCountryCode = async (): Promise<CountryCode> => {
	const response = await API.get<CountryCode>('/api/country-check');
	return { code: response.data.code };
};
